.form-row + .form-row {
  margin: var(--padding-md) 0 0;
}

.form-row + [data-class="wpcf7cf_group"] {
  margin: var(--padding-md) 0;
}

[data-class="wpcf7cf_group"] + .form-row {
  margin: var(--padding-md) 0;
}

.form-row__label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;

  font-family: var(--heading-font);
  color: var(--color-grey-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
}

// Single line field styles

.form-row input[type="text"],
.form-row input[type="number"],
.form-row input[type="url"],
.form-row input[type="password"],
.form-row input[type="email"],
.form-row input[type="tel"],
.form-row input[type="date"],
.form-row select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);

  font-size: var(--fs-fluid-xs);

    &:focus {
      outline: none;
      border-color: var(--color-brand-red);
    }

}

.form-row input[type="date"] {
  text-transform: uppercase;
}

.form-row input[type="submit"] {
  @extend .btn;
  @extend .btn--purple-fade;
}

// Textarea field styles

.form-row textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 1rem;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);

  font-size: var(--fs-fluid-sm);

    &:focus {
      outline: none;
      border-color: var(--color-brand-red);
    }

}

// Checkboxes and radios

.wpcf7-checkbox,
.wpcf7-radio {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
}

.wpcf7-checkbox .wpcf7-list-item label,
.wpcf7-radio .wpcf7-list-item label {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.form-row .wpcf7-checkbox .wpcf7-list-item-label,
.form-row .wpcf7-radio .wpcf7-list-item-label {
  font-family: var(--heading-font);
  color: var(--color-grey-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
}

// GDPR

.wpcf7-acceptance {
  display: block;
  width: 100%;
  padding: var(--padding-xs);

  border: 1px solid var(--color-brand-red-fade);
}

.wpcf7-acceptance input {
  margin: 0 1rem 0 0;
}

.wpcf7-acceptance .wpcf7-list-item-label {
  font-size: var(--fs-fluid-xs);
  color: var(--color-brand-red-mid);
  line-height: var(--line-height-md);

    a {
      color: inherit;
    }
    
}

// Validation

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin: 0;

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  color: var(--color-brand-red);
  letter-spacing: var(--kerning-sm);

  background-color: #FAE5E5;
}

.wpcf7-response-output {
  display: block;
  width: 100%;
  padding: 1rem;

  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  font-size: var(--fs-fluid-sm);
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;

  background-color: var(--color-error);
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;
  
  background-color: var(--color-success);
}

// Variants

.bg-grey-light {

  .form-row input[type="text"],
  .form-row input[type="number"],
  .form-row input[type="url"],
  .form-row input[type="password"],
  .form-row input[type="email"],
  .form-row input[type="tel"],
  .form-row input[type="date"],
  .form-row select,
  .form-row textarea {
    background-color: white;
  }

}