.timeline-outer {
  position: relative;
  padding: var(--padding-lg) 0;

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 1rem;
      z-index: 1;

      display: block;
      width: 0.5rem;
      height: 100%;
      
      background-color: var(--color-grey);

        @include mq(screen-sm) {
          left: calc(50% - 0.25rem);
        }

    }

}

.bg-grey-light .timeline-outer:before {
  background-color: white;
}

.timeline {
  display: grid;
  gap: var(--padding-lg);
  position: relative;
  z-index: 2;
}

.timeline__item {
  padding: 0 0 0 5rem;

    @include mq(screen-sm) {
      display: grid;
      gap: var(--padding-sm);
      align-items: center;
      width: 50%;
    }

}

.timeline__img-outer {
  position: relative;
  margin: 0 0 var(--padding-md);

    @include mq(screen-sm) {
      margin: 0;
    }

    &:before {
      content: "";

      position: absolute;
      top: calc(5rem - 1px);
      left: -4rem;
      z-index: 1;

      width: 5rem;
      height: 2px;

      background-color: var(--color-grey-mid);
    }

    &:after {
      content: "";

      position: absolute;
      top: calc(5rem - 1rem);
      left: -4.75rem;
      z-index: 2;

      width: 2rem;
      height: 2rem;

      background-color: var(--color-grey-mid);
      border-radius: 100%;
    }

}

.timeline__item:nth-child(odd) {
  
  @include mq(screen-sm) {
    display: grid;
    grid-template-columns: 1fr 10rem;
    padding: 0 5rem 0 0;

    text-align: right;

      .timeline__img-outer {
        order: 2;

          &:before {
            left: auto;
            right: -5rem;
          }
      
          &:after {
            left: auto;
            right: -6rem;
          }

      }

      .timeline__text {
        order: 1;
      }

  }

}

.timeline__item:nth-child(even) {
  
  @include mq(screen-sm) {
    display: grid;
    grid-template-columns: 10rem 1fr;
    justify-self: end;

      .timeline__img-outer {

          &:before {
            left: -5rem;
          }

          &:after {
            left: -6rem;
          }


      }
  }

}


.timeline__img {
  position: relative;
  z-index: 2;

  display: block;
  width: 10rem;
  height: 10rem;

  border-radius: 100%;
}

.timeline__text {
  padding: 0 0 0 0.5rem;

    @include mq(screen-sm) {
      padding: 0;
    }
    
}