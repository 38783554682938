.accordion-block + .accordion-block {
  border-top: 1px solid var(--color-grey);
}

.accordion-block__toggle {
  display: flex;
  gap: var(--padding-lg);
  justify-content: space-between;
  width: 100%;
  padding: var(--padding-md) 0;

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-mid);

  background: none;

    &.is-active {
      color: var(--color-brand-purple);

        svg {
          transform: rotate(-90deg);
        }
        
    }

    &:hover {
      color: var(--color-grey-dark);
    }

    svg {
      width: 2rem;

      fill: currentColor;
      transform: rotate(90deg);
    }

}

.accordion-block__answer {
  display: none;

    &.is-open {
      display: block;
      padding: 0 0 var(--padding-md);
    }

}