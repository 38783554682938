.people-grid-outer {
  display: grid;
  gap: var(--padding-lg);
  
    @include mq(screen-sm) {
      grid-template-columns: 1fr 2fr;
    }

    @include mq(screen-lg) {
      gap: var(--padding-xl);
      grid-template-columns: 1fr 3fr;
    }

}

.people-grid-text {
  text-align: center;

    @include mq(screen-xs) {
      text-align: left;
    }

}

.people-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--padding-xl);

    @include mq(screen-sm) {
      //justify-content: flex-start;
    }
}

.people-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
}

.people-grid__item__img-link {
  display: block;
}

.people-grid__item__img {
  display: block;
  width: 15rem;
  height: 15rem;

  border-radius: 100%;
}

.people-grid__item__info {
  text-align: center;

    .title:hover {
      color: var(--color-brand-red);
    }
    
}

// Sections

.people-group + .people-group {
  
  .people-grid-outer {
    padding: var(--padding-xl) 0 0;
    margin: var(--padding-xl) 0 0;

    border-top: 1px solid var(--color-grey);
  }

}