/*!
Theme Name: rradar
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/

:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1280;
  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1.2rem) / (var(--fluid-max-width) - var(--fluid-min-width)));

  --fs-xxl-min: 35;
  --fs-xxl-max: 80;
  --fs-fluid-xxl: calc(((var(--fs-xxl-min) / 16) * 1.2rem) + (var(--fs-xxl-max) - var(--fs-xxl-min)) * var(--fluid-bp));
  --fs-fixed-xxl: 8rem;

  --fs-xl-min: 32;
  --fs-xl-max: 54;
  // --fs-fluid-xl: calc(((var(--fs-xl-min) / 16) * 1.2rem) + (var(--fs-xl-max) - var(--fs-xl-min)) * var(--fluid-bp));
  --fs-fluid-xl: clamp(3.2rem, 2.4667rem + 2.2917vw, 5.4rem);
  --fs-fixed-xl: 6rem;

  --fs-lg-min: 27;
  --fs-lg-max: 40;
  // --fs-fluid-lg: calc(((var(--fs-lg-min) / 16) * 1.2rem) + (var(--fs-lg-max) - var(--fs-lg-min)) * var(--fluid-bp));
  --fs-fluid-lg: clamp(2.7rem, 2.2667rem + 1.3542vw, 4rem);
  --fs-fixed-lg: 4rem;

  --fs-md-min: 21;
  --fs-md-max: 30;
  // --fs-fluid-md: calc(((var(--fs-md-min) / 16) * 1.2rem) + (var(--fs-md-max) - var(--fs-md-min)) * var(--fluid-bp));
  --fs-fluid-md: clamp(1.8000rem, 1.5000rem + 0.9375vw, 2.7000rem);
  --fs-fixed-md: 2.5rem;

  --fs-sm-min: 18;
  --fs-sm-max: 24;
  // --fs-fluid-sm: calc(((var(--fs-sm-min) / 16) * 1.2rem) + (var(--fs-sm-max) - var(--fs-sm-min)) * var(--fluid-bp));
  --fs-fluid-sm: clamp(1.8000rem, 1.6000rem + 0.6250vw, 2.4000rem);
  --fs-fixed-sm: 2.1rem;

  --fs-xs-min: 16;
  --fs-xs-max: 21;
  // --fs-fluid-xs: calc(((var(--fs-xs-min) / 16) * 1.2rem) + (var(--fs-xs-max) - var(--fs-xs-min)) * var(--fluid-bp));
  --fs-fluid-xs: clamp(1.4000rem, 1.2667rem + 0.4167vw, 1.8000rem);
  --fs-fixed-xs: 1.8rem;


  // --fs-fluid-xxl: clamp(2.1875rem, 1.25rem + 4.6875vw, 5rem);
  // --fs-fluid-xl: clamp(2rem, 1.4167rem + 2.9167vw, 3.75rem);
  // //--fs-fluid-lg: clamp(1.6875rem, 1.4167rem + 1.3542vw, 2.5rem);
  // --fs-fluid-lg: clamp(1.3125rem, 1.125rem + 0.9375vw, 1.875rem);
  // --fs-fluid-md: clamp(1.125rem, 1rem + 0.625vw, 1.5rem);
  // --fs-fluid-sm: clamp(1rem, 0.8958rem + 0.5208vw, 1.3125rem);
  // --fs-fluid-xs: clamp(0.75rem, 0.6667rem + 0.4167vw, 1rem);

  --padding-xl-min: 60;
  --padding-xl-max: 120;
  --padding-xl: calc(((var(--padding-xl-min) / 16) * 1rem) + (var(--padding-xl-max) - var(--padding-xl-min)) * var(--fluid-bp));

  --padding-lg-min: 30;
  --padding-lg-max: 60;
  --padding-lg: calc(((var(--padding-lg-min) / 16) * 1rem) + (var(--padding-lg-max) - var(--padding-lg-min)) * var(--fluid-bp));

  --padding-md-min: 20;
  --padding-md-max: 40;
  --padding-md: calc(((var(--padding-md-min) / 16) * 1rem) + (var(--padding-md-max) - var(--padding-md-min)) * var(--fluid-bp));

  --padding-sm-min: 15;
  --padding-sm-max: 30;
  --padding-sm: calc(((var(--padding-sm-min) / 16) * 1rem) + (var(--padding-sm-max) - var(--padding-sm-min)) * var(--fluid-bp));

  --padding-xs-min: 10;
  --padding-xs-max: 20;
  --padding-xs: calc(((var(--padding-xs-min) / 16) * 1rem) + (var(--padding-xs-max) - var(--padding-xs-min)) * var(--fluid-bp));

  --heading-font: 'DM Sans', sans-serif;
  --body-font: 'Roboto', sans-serif;
  --body-font-bold: 'UniversLTStd-Std', sans-serif;

  --font-weight-bold: 500;

  --line-height-sm: 1.25;
  --line-height-md: 1.5;
  --line-height-lg: 1.75;

  --kerning-sm: -0.02em;
  --kerning-lg: -0.05em;

  --color-brand-red: #cc0000;
  --color-brand-red-mid: #9e1733;
  --color-brand-red-dark: #641333;
  --color-brand-orange: #fe7822;
  --color-brand-purple: #4c1d5e;
  --color-grey-light: #f7f7f7;
  --color-grey: #ededed;
  --color-grey-mid: #333;
  --color-grey-dark: #363230;
  --color-error: #DB0000;
  --color-success: #55a630;

  --color-twitter: #1D9BF0;
  --color-linkedin: #0A66C2;
  --color-instagram: white;

  --color-brand-red-fade: rgba(201, 0, 0, 0.1);
  --color-brand-red-mid-fade:rgba(158, 23, 51, 0.1);
  --color-brand-red-dark-fade: rgba(100, 19, 51, 0.1);
  --color-brand-orange-fade: rgba(254, 120, 34, 0.1);
  --color-brand-purple-fade: rgba(76, 29, 94, 0.1);

  --mq-xs: 640px;
  --mq-sm: 768px;
  --mq-md: 1024px;
  --mq-lg: 1280px;
  --mq-xl: 1536px;
  --mq-xxl: 1800px;

  --transition-hover: 0.2s ease-out;
  --arrow-hover: translateX(0.5rem);

  --btn-radius: 0.5rem;
  --feed-item-shadow: 0px 2px 4px rgba(0, 0, 0,0.05), 0px 4px 8px rgba(0, 0, 0,0.05);
  --feed-item-shadow-reverse: 0px -2px 4px rgba(0, 0, 0,0.05), 0px -4px 8px rgba(0, 0, 0,0.05);
}

@media screen and (min-width: 1280px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

.temp-nav {
  display: flex;
  font-size: 1.4rem;

    li {
      padding: 0.5rem;
    }
    
}

@import "config/fonts";
@import "config/variables";
@import "config/mixins";
@import "config/media-queries";

@import "global/reset";
@import "global/helpers";
@import "global/layout";
@import "global/spacing";
@import "global/header";
@import "global/footer";
@import "global/typography";

@import "plugins/lite-yt-embed";
@import "plugins/theme-my-login";
@import "plugins/tiny-slider";

@import "components/buttons";
@import "components/hero";
@import "components/call-to-action";
@import "components/quicklinks";
@import "components/topics";
@import "components/testimonials";
@import "components/newsletter-cta";
@import "components/form-fields";
@import "components/breadcrumbs";
@import "components/two-col-text";
@import "components/std-content";
@import "components/content-feed";
@import "components/people-grid";
@import "components/content-carousel";
@import "components/featured-content-grid";
@import "components/contact-form";
@import "components/related-content";
@import "components/text-hero";
@import "components/accordion";
@import "components/faqs";
@import "components/vacancies";
@import "components/basic-content";
@import "components/contact-info";
@import "components/login-landing";
@import "components/product-hero";
@import "components/search";
@import "components/client-list";
@import "components/img-quicklinks";
@import "components/text-img";
@import "components/locations";
@import "components/social-links";
@import "components/timeline";
@import "components/download-form";