// Tag buttons

.topics {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.topic {
  display: block;
  height: 2.5rem;
  padding: 0 1rem;

  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.mixitup-control-active {
      color: white;
    }


}

.topic--grey-dark {
  background-color: var(--color-grey-light);
  color: var(--color-grey-dark);

    &.mixitup-control-active {
      background-color: var(--color-grey-dark);
    }

}

.topic--red-mid {
  background-color: var(--color-brand-red-mid-fade);
  color: var(--color-brand-red-mid);

    &.mixitup-control-active {
      background-color: var(--color-brand-red-mid);
    }

}

.topic--orange {
  background-color: var(--color-brand-orange-fade);
  color: var(--color-brand-orange); 

    &.mixitup-control-active {
      background-color: var(--color-brand-orange);
    }

}

.topic--purple {
  background-color: var(--color-brand-purple-fade);
  color: var(--color-brand-purple);

    &.mixitup-control-active {
      background-color: var(--color-brand-purple);
    }

}

.topic--red {
  background-color: var(--color-brand-red-fade);
  color: var(--color-brand-red);

    &.mixitup-control-active {
      background-color: var(--color-brand-red);
    }

}

// Quicklinks

.topic-quicklinks {
  display: grid;
  gap: var(--padding-sm);

    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-lg) {
      grid-template-columns: repeat(4, 1fr);
    }

}

// .topic-quicklink {
//   position: relative;
//   display: block;
//   padding: 0 0 var(--padding-xl);

//     svg {
//       position: absolute;
//       bottom: var(--padding-sm);
//       left: var(--padding-sm);
//       z-index: 1;

//       width: min(10%, 3rem);
//     }

// }

.topic-quicklink {
  position: relative;
  display: block;
  height: 100%;
  padding: var(--padding-md) var(--padding-sm) var(--padding-xl);

  border-bottom: 3px solid;

  text-decoration: none;

    svg {
      position: absolute;
      bottom: var(--padding-sm);
      left: var(--padding-sm);
      z-index: 1;

      width: min(10%, 3rem);
    }

    &:hover {
      border-color: var(--color-grey-dark);

        .title {
          text-decoration: underline;
        }

        svg {
          transform: var(--arrow-hover);
        }

    }

    .supporting-text {
      color: var(--color-grey-dark);
    }

}

.topic-quicklink--red-mid {
  background-color: #f6e8eb;
  border-color: var(--color-brand-red-mid);
  
    .title {
      color: var(--color-brand-red-mid);
    }

}

.topic-quicklink--orange {
  background-color: #fff2e9;
  border-color: var(--color-brand-orange);

    .title {
      color: var(--color-brand-orange); 
    }

}

.topic-quicklink--purple {
  background-color: #ede8ef;
  border-color: var(--color-brand-purple);

    .title {
      color: var(--color-brand-purple);
    }

}

.topic-quicklink--red {
  background-color: #fae6e6;
  border-color: var(--color-brand-red);

    .title {
      color: var(--color-brand-red);
    }

}