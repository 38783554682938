.product-hero-outer {
  position: relative;
  background: linear-gradient(0, rgba(247,247,247,1) 0%, rgba(240,240,240,1) 100%);

    .hero {
      padding: var(--padding-lg) 0;

        @include mq(screen-lg) {
          padding: 0;
        }

    }

}

.product-hero-logo {
  display: block;
  width: auto;
  max-height: 5rem;

  mix-blend-mode: multiply;
}

.product-hero-imgs {
  display: none;

  position: relative;
  width: 60%;
  margin: var(--padding-xs) 0 0;

  overflow: hidden;

    @include mq(screen-sm) {
      order: 2;
      display: block;
    }

}

.product-hero-imgs__img {
  position: relative;
  z-index: 2;

  display: block;
  width: 100%;
  height: auto;
}