.hero-outer {
  position: relative;
  background: linear-gradient(0, rgba(247,247,247,1) 0%, rgba(240,240,240,1) 100%);

    &:after {

      @include mq(screen-lg) {
        content: "";

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 4rem;

        background-color: white;
      }

    }

    .hero {
      padding: var(--padding-lg) 0;

        @include mq(screen-lg) {
          padding: 0;
        }

    }

    .hero-imgs {
      
      @include mq(screen-sm) {
        transform: translateY(-2rem);
      }

    }

    > .inner {
      position: relative;
      z-index: 2;
    }

}

.hero {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--padding-lg);


    @include mq(screen-sm) {
      flex-direction: row;
      align-items: flex-start;
    }

}

.hero-content {
  order: 2;

  position: relative;
  z-index: 2;

  width: 100%;
  //padding: 0 var(--padding-lg) 0 0;
  
    @include mq(screen-sm) {
      order: 1;

      width: 60%;
      padding: var(--padding-lg) var(--padding-lg) var(--padding-xl) 0;
      margin: 0;
    }

    p {
      @extend .supporting-text;
    }

    p + p {
      margin: var(--padding-xs) 0 0;
    }

    p a {
      color: var(--color-grey-dark);
    }

    p a:hover {
      color: var(--color-brand-red);
    }

    p + .btn-group {
      margin: var(--padding-md) 0 0;
    }

}

.hero-content-title {
  position: relative;
}

.hero-imgs {
  order: 1;

  // position: absolute;
  // top: 0;
  // left: 50%;
  // z-index: 1;

  width: 35rem;
  height: 30rem;

    @include mq(screen-sm) {
      order: 2;
      left: 0;

      position: relative;
      width: 60%;
      height: auto;
    }

    &:after {
      content: "";

      position: absolute;
      z-index: 5;
      top: 15rem;
      right: -5rem;
      transform: translateY(-50%);

      aspect-ratio: 1/1;
      // width: 50%;
      width: 20rem;

      border-radius: 100%;
      mix-blend-mode: multiply;

      transition: transform 1s ease-out;

        @include mq(screen-sm) {
          top: 50%;

          width: 50%;
        }

    }
    
}

.hero-imgs__img {
  border-radius: 100%;
}

.hero-imgs__img--1 {
  position: relative;
  z-index: 1;
}

// Internal hero specific styles

.hero-imgs--int {

  svg {
    // display: none;

    display: block;
    width: 75%;
    height: auto;

    fill: rgba(white, 0.5);

      @include mq(screen-sm) {
        display: none;
      }

  }

  img {
    display: block;
    width: auto;
    height: 30rem;

      @include mq(screen-sm) {
        display: block;
        width: 75%;
        height: auto;
      }

  }

  
  &:after {
    background: url('img/rradar-1.svg');
    background-size: 100% auto;
    background-position: center center;
  }

}

// Animated title

// Homepage specific styles

.hero--home {
  align-items: flex-end;
  padding: var(--padding-md) 0 0;

    @include mq(screen-sm) {
      padding: var(--padding-lg) 0 0;
    }

}

.hero--home .hero-content {

  @include mq(screen-sm) {
    order: 1;

    width: 75%;
    padding: var(--padding-lg) var(--padding-lg) 0 0;
    margin: 0;
  }

  @include mq(screen-lg) {
    width: 60%;
  }

}

.hero-content-text-outer {
  position: relative;
  overflow: hidden;
}

.hero-content-text {
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
}

.hero-content-text:not(:first-child) {
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateY(5rem);
}

.hero-imgs--home {

  img {
    display: block;
    width: auto;
    height: 30rem;

      @include mq(screen-sm) {
        display: block;
        width: 75%;
        height: auto;
      }

  }

  .hero-imgs__img:not(:first-of-type) {
    position: absolute;
    top: var(--padding-md);

      @include mq(screen-sm) {
        top: 0;
      }

  }
  
  &:after {
    background-color: var(--color-brand-red);
  }

  .hero-imgs__img--1 {
    z-index: 4;
  }

  .hero-imgs__img--2 {
    z-index: 3;
  }

  .hero-imgs__img--3 {
    z-index: 2;
  }

}


// Parallax blob on home hero

.hero.is-scrolling {
  
  .hero-imgs:after {
    transform: translateY(-65%);
  }

}