// Titles

.title {
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-dark);
}

.title--xxl {
  font-size: var(--fs-fluid-xxl);
  //line-height: var(--line-height-sm);
  line-height: 1.1;
}

.title--xl {
  font-size: var(--fs-fluid-xl);
  line-height: var(--line-height-sm);
}

.title--lg {
  font-size: var(--fs-fluid-lg);
  line-height: var(--line-height-sm);
}

.title--md {
  font-size: var(--fs-fluid-md);
  line-height: var(--line-height-sm);
}

.title--sm {
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-sm);
}


// Copy

.supporting-text {
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
}

.feed-text {
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
}

// Colours

.text-red {
  color: var(--color-brand-red);
}

.text-red-mid {
  color: var(--color-brand-red-mid);
}

.text-orange {
  color: var(--color-brand-orange);
}

.text-purple {
  color: var(--color-brand-purple);
}

.text-white {
  color: white;

    a {
      color: inherit;
    }
    
}

.text-grey-dark {
  color: var(--color-grey-dark);
}