/*!
Theme Name: rradar
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/
:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1280;
  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1.2rem) / (var(--fluid-max-width) - var(--fluid-min-width)));
  --fs-xxl-min: 35;
  --fs-xxl-max: 80;
  --fs-fluid-xxl: calc(((var(--fs-xxl-min) / 16) * 1.2rem) + (var(--fs-xxl-max) - var(--fs-xxl-min)) * var(--fluid-bp));
  --fs-fixed-xxl: 8rem;
  --fs-xl-min: 32;
  --fs-xl-max: 54;
  --fs-fluid-xl: clamp(3.2rem, 2.4667rem + 2.2917vw, 5.4rem);
  --fs-fixed-xl: 6rem;
  --fs-lg-min: 27;
  --fs-lg-max: 40;
  --fs-fluid-lg: clamp(2.7rem, 2.2667rem + 1.3542vw, 4rem);
  --fs-fixed-lg: 4rem;
  --fs-md-min: 21;
  --fs-md-max: 30;
  --fs-fluid-md: clamp(1.8000rem, 1.5000rem + 0.9375vw, 2.7000rem);
  --fs-fixed-md: 2.5rem;
  --fs-sm-min: 18;
  --fs-sm-max: 24;
  --fs-fluid-sm: clamp(1.8000rem, 1.6000rem + 0.6250vw, 2.4000rem);
  --fs-fixed-sm: 2.1rem;
  --fs-xs-min: 16;
  --fs-xs-max: 21;
  --fs-fluid-xs: clamp(1.4000rem, 1.2667rem + 0.4167vw, 1.8000rem);
  --fs-fixed-xs: 1.8rem;
  --padding-xl-min: 60;
  --padding-xl-max: 120;
  --padding-xl: calc(((var(--padding-xl-min) / 16) * 1rem) + (var(--padding-xl-max) - var(--padding-xl-min)) * var(--fluid-bp));
  --padding-lg-min: 30;
  --padding-lg-max: 60;
  --padding-lg: calc(((var(--padding-lg-min) / 16) * 1rem) + (var(--padding-lg-max) - var(--padding-lg-min)) * var(--fluid-bp));
  --padding-md-min: 20;
  --padding-md-max: 40;
  --padding-md: calc(((var(--padding-md-min) / 16) * 1rem) + (var(--padding-md-max) - var(--padding-md-min)) * var(--fluid-bp));
  --padding-sm-min: 15;
  --padding-sm-max: 30;
  --padding-sm: calc(((var(--padding-sm-min) / 16) * 1rem) + (var(--padding-sm-max) - var(--padding-sm-min)) * var(--fluid-bp));
  --padding-xs-min: 10;
  --padding-xs-max: 20;
  --padding-xs: calc(((var(--padding-xs-min) / 16) * 1rem) + (var(--padding-xs-max) - var(--padding-xs-min)) * var(--fluid-bp));
  --heading-font: "DM Sans", sans-serif;
  --body-font: "Roboto", sans-serif;
  --body-font-bold: "UniversLTStd-Std", sans-serif;
  --font-weight-bold: 500;
  --line-height-sm: 1.25;
  --line-height-md: 1.5;
  --line-height-lg: 1.75;
  --kerning-sm: -0.02em;
  --kerning-lg: -0.05em;
  --color-brand-red: #cc0000;
  --color-brand-red-mid: #9e1733;
  --color-brand-red-dark: #641333;
  --color-brand-orange: #fe7822;
  --color-brand-purple: #4c1d5e;
  --color-grey-light: #f7f7f7;
  --color-grey: #ededed;
  --color-grey-mid: #333;
  --color-grey-dark: #363230;
  --color-error: #DB0000;
  --color-success: #55a630;
  --color-twitter: #1D9BF0;
  --color-linkedin: #0A66C2;
  --color-instagram: white;
  --color-brand-red-fade: rgba(201, 0, 0, 0.1);
  --color-brand-red-mid-fade:rgba(158, 23, 51, 0.1);
  --color-brand-red-dark-fade: rgba(100, 19, 51, 0.1);
  --color-brand-orange-fade: rgba(254, 120, 34, 0.1);
  --color-brand-purple-fade: rgba(76, 29, 94, 0.1);
  --mq-xs: 640px;
  --mq-sm: 768px;
  --mq-md: 1024px;
  --mq-lg: 1280px;
  --mq-xl: 1536px;
  --mq-xxl: 1800px;
  --transition-hover: 0.2s ease-out;
  --arrow-hover: translateX(0.5rem);
  --btn-radius: 0.5rem;
  --feed-item-shadow: 0px 2px 4px rgba(0, 0, 0,0.05), 0px 4px 8px rgba(0, 0, 0,0.05);
  --feed-item-shadow-reverse: 0px -2px 4px rgba(0, 0, 0,0.05), 0px -4px 8px rgba(0, 0, 0,0.05);
}

@media screen and (min-width: 1280px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}
.temp-nav {
  display: flex;
  font-size: 1.4rem;
}
.temp-nav li {
  padding: 0.5rem;
}

@font-face {
  font-family: "UniversLTStd";
  src: url("fonts/UniversLTStd.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "UniversLTStd-Light";
  src: url("fonts/UniversLTStd-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--fs-fluid-xs);
  font-weight: 300;
  font-style: normal;
  color: var(--color-grey-mid);
  background-color: white;
}

[id] {
  scroll-margin-top: 2rem;
}

h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-size: inherit;
  font-weight: 300;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

strong {
  font-weight: normal;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  font-size: 62.5%; /* 1rem = 10px */
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=date],
input[type=month],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=week],
input[type=number],
input[type=search],
input[type=tel],
input[type=color],
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

select {
  font-size: inherit;
}

button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.screen-reader-text {
  display: none;
}

ul, ol {
  list-style-type: none;
}

.flex-img {
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex-img {
    flex-shrink: 0;
  }
}

.align-center {
  text-align: center;
}

.screen-reader-only {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.bg-grey-light {
  background-color: var(--color-grey-light);
}

.main {
  overflow: hidden;
}

.outer {
  position: relative;
}

.inner {
  margin: 0 auto;
  padding: 0 var(--padding-md);
}

.inner--sm {
  max-width: var(--mq-sm);
}

.inner--md {
  max-width: var(--mq-md);
}

.inner--lg {
  max-width: var(--mq-lg);
}

.inner--xl {
  max-width: var(--mq-xl);
}

.inner--xxl {
  max-width: var(--mq-xxl);
}

.mt-xs {
  margin-top: var(--padding-xs);
}

.mt-sm {
  margin-top: var(--padding-sm);
}

.mt-md {
  margin-top: var(--padding-md);
}

.mt-lg {
  margin-top: var(--padding-lg);
}

.mt-xl {
  margin-top: var(--padding-xl);
}

.mb-xs {
  margin-bottom: var(--padding-xs);
}

.mb-sm {
  margin-bottom: var(--padding-sm);
}

.mb-md {
  margin-bottom: var(--padding-md);
}

.mb-lg {
  margin-bottom: var(--padding-lg);
}

.mb-xl {
  margin-bottom: var(--padding-xl);
}

.mtb-xs {
  margin: var(--padding-xs) 0;
}

.mtb-sm {
  margin: var(--padding-sm) 0;
}

.mtb-md {
  margin: var(--padding-md) 0;
}

.mtb-lg {
  margin: var(--padding-lg) 0;
}

.mtb-xl {
  margin: var(--padding-xl) 0;
}

.m-xs {
  margin: var(--padding-xs);
}

.m-sm {
  margin: var(--padding-sm);
}

.m-md {
  margin: var(--padding-md);
}

.m-lg {
  margin: var(--padding-lg);
}

.m-xl {
  margin: var(--padding-xl);
}

.pt-xs {
  padding-top: var(--padding-xs);
}

.pt-sm {
  padding-top: var(--padding-sm);
}

.pt-md {
  padding-top: var(--padding-md);
}

.pt-lg {
  padding-top: var(--padding-lg);
}

.pt-xl {
  padding-top: var(--padding-xl);
}

.pb-xs {
  padding-bottom: var(--padding-xs);
}

.pb-sm {
  padding-bottom: var(--padding-sm);
}

.pb-md {
  padding-bottom: var(--padding-md);
}

.pb-lg {
  padding-bottom: var(--padding-lg);
}

.pb-xl {
  padding-bottom: var(--padding-xl);
}

.ptb-xs {
  padding: var(--padding-xs) 0;
}

.ptb-sm {
  padding: var(--padding-sm) 0;
}

.ptb-md {
  padding: var(--padding-md) 0;
}

.ptb-lg {
  padding: var(--padding-lg) 0;
}

.ptb-xl {
  padding: var(--padding-xl) 0;
}

.p-xs {
  padding: var(--padding-xs);
}

.p-sm {
  padding: var(--padding-sm);
}

.p-md {
  padding: var(--padding-md);
}

.p-lg {
  padding: var(--padding-lg);
}

.p-xl {
  padding: var(--padding-xl);
}

.tel-cta-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-sm) var(--padding-md);
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .tel-cta-outer {
    width: var(--mq-lg);
    padding: var(--padding-sm) var(--padding-md) 0;
  }
}

.tel-cta {
  position: relative;
}
.tel-cta p {
  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-xs);
  color: var(--color-brand-red);
}
.tel-cta span {
  color: var(--color-grey-dark);
}
.tel-cta svg {
  position: absolute;
  top: 50%;
  right: 95%;
  transform: translateY(-50%);
  z-index: 1;
  width: 4rem;
  height: 4rem;
  fill: var(--color-brand-purple-fade);
}

.pg-header-outer > .tel-cta {
  display: none;
}
@media (min-width: 1280px) {
  .pg-header-outer > .tel-cta {
    display: block;
  }
}

@media (min-width: 1280px) {
  .pg-header__btns .tel-cta {
    display: none;
  }
}

.pg-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-sm);
  max-width: var(--mq-lg);
  height: 8rem;
  padding: 0 var(--padding-md);
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .pg-header {
    gap: var(--padding-md);
    height: 15rem;
  }
}

.pg-header__logo {
  display: block;
  width: 6rem;
  height: 6rem;
}
@media (min-width: 1280px) {
  .pg-header__logo {
    width: 8rem;
    height: 8rem;
  }
}

.main-nav {
  display: none;
  position: fixed;
  top: 13.5rem;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: var(--padding-lg) var(--padding-md);
  overflow-y: scroll;
  background-color: var(--color-grey-light);
  box-shadow: inset var(--feed-item-shadow);
}
@media (min-width: 1024px) {
  .main-nav {
    position: static;
    display: flex;
    width: auto;
    height: auto;
    padding: 0;
    background: none;
    box-shadow: none;
    overflow-y: visible;
  }
}

.main-nav__list {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 0 16rem;
  display: grid;
  gap: 2rem;
}
@media (min-width: 1024px) {
  .main-nav__list {
    display: flex;
    gap: var(--padding-md);
    padding: 0;
  }
}
.main-nav__list > li {
  position: relative;
}
@media (min-width: 1024px) {
  .main-nav__list > li {
    padding: var(--padding-sm) 0;
  }
}
@media (min-width: 1024px) {
  .main-nav__list li.child {
    display: none;
  }
}
.main-nav__list a {
  text-decoration: none;
}
.main-nav__list a:hover {
  text-decoration: underline;
}
.main-nav__list .sub-menu {
  display: none;
  gap: var(--padding-md);
  margin: var(--padding-md) 0 0;
  padding: 0 0 0 var(--padding-xs);
  border-left: 3px solid var(--color-grey);
}
@media (min-width: 1024px) {
  .main-nav__list .sub-menu {
    display: grid;
    gap: 0;
    margin: 0;
    padding: 1rem 0;
    border: none;
  }
}
.main-nav__list .sub-menu.is-open {
  display: grid;
}
@media (min-width: 1024px) {
  .main-nav__list .sub-menu li {
    padding: 1rem var(--padding-sm);
  }
}
@media (min-width: 1024px) {
  .main-nav__list > li > .sub-menu {
    display: grid;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0);
    width: 30rem;
    border: none;
    border-radius: var(--btn-radius);
    background-color: var(--color-brand-red-mid);
  }
}
@media (min-width: 1024px) {
  .main-nav__list > li:hover a {
    color: var(--color-brand-red);
  }
}
@media (min-width: 1024px) {
  .main-nav__list > li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(1rem);
    z-index: 1;
  }
}

@media (min-width: 1024px) {
  .sub-menu {
    position: absolute;
  }
  .sub-menu > .parent > .sub-menu {
    display: grid;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: calc(100% - 2rem);
    z-index: 2;
    width: 30rem;
    border-radius: var(--btn-radius);
    background-color: #91152e;
  }
  .sub-menu > .parent:hover > a {
    text-decoration: underline;
  }
  .sub-menu > .parent:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  .sub-menu > .child {
    display: none;
  }
}
.main-nav__list li.parent > a {
  position: relative;
  display: block;
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  color: var(--color-grey-dark);
  text-decoration: none;
  letter-spacing: var(--kerning-lg);
}
@media (min-width: 1024px) {
  .main-nav__list li.parent > a {
    font-size: var(--fs-fluid-xs);
  }
}

@media (min-width: 1024px) {
  .main-nav__list li.parent li.parent a {
    color: white;
  }
}

.main-nav__list li:not(.parent) > a {
  font-size: var(--fs-fluid-sm);
  color: var(--color-brand-red-mid);
}
@media (min-width: 1024px) {
  .main-nav__list li:not(.parent) > a {
    font-size: var(--fs-fluid-xs);
    color: white;
  }
}

.main-nav__list li.parent > a:after {
  content: "";
  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--color-grey-dark) transparent transparent transparent;
}
@media (min-width: 1024px) {
  .main-nav__list li.parent > a:after {
    display: none;
  }
}

.mob-btns {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: grid;
  place-items: center;
  width: 100%;
  height: 8rem;
  padding: 0 var(--padding-md);
  background-color: white;
  border-top: 1px solid white;
  box-shadow: var(--feed-item-shadow-reverse);
}
@media (min-width: 768px) {
  .mob-btns {
    display: none;
  }
}

.pg-header__btns {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex: auto;
}

.search-toggle,
.main-nav-toggle {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  padding: 1.25rem;
  background: var(--color-brand-red-fade);
  border-radius: var(--btn-radius);
}
.search-toggle:hover,
.main-nav-toggle:hover {
  background: var(--color-brand-red);
}
.search-toggle:hover svg,
.main-nav-toggle:hover svg {
  fill: white;
}
.search-toggle svg,
.main-nav-toggle svg {
  fill: var(--color-brand-red);
}

@media (min-width: 1024px) {
  .main-nav-toggle {
    display: none;
  }
}
.main-nav-toggle svg:last-child {
  display: none;
}
.main-nav-toggle.is-active {
  background-color: var(--color-brand-red-fade);
}
.main-nav-toggle.is-active svg:first-child {
  display: none;
}
.main-nav-toggle.is-active svg:last-child {
  display: block;
}

.pg-header-outer.nav-is-open .main-nav {
  display: block;
}

.pg-footer-outer {
  padding: var(--padding-xl) 0;
  background: linear-gradient(0, rgb(247, 247, 247) 0%, rgb(240, 240, 240) 100%);
}

.pg-footer {
  display: grid;
  gap: var(--padding-xl);
  transform: translateY(100px);
  transition: all 0.75s ease-in-out;
  opacity: 0;
}
@media (min-width: 768px) {
  .pg-footer {
    grid-template-columns: 1fr 1fr;
  }
}

.pg-footer-outer.in-viewport .pg-footer {
  transform: translateY(0);
  opacity: 1;
}

.pg-footer .contact-info a {
  color: var(--color-grey-dark);
}
.pg-footer .contact-info a:hover {
  color: var(--color-brand-red);
  text-decoration: underline;
}

.pg-footer-contacts {
  display: flex;
  flex-direction: column;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .pg-footer-contacts {
    flex-direction: row;
  }
}

.pg-footer-contacts__logo {
  display: block;
  width: 5rem;
  height: 5rem;
}
@media (min-width: 1280px) {
  .pg-footer-contacts__logo {
    width: 8rem;
    height: 8rem;
  }
}

.pg-footer-nav-outer {
  display: grid;
  align-content: start;
  gap: var(--padding-lg);
}

.pg-footer-nav {
  display: grid;
  gap: var(--padding-lg);
  align-items: start;
}
@media (min-width: 1280px) {
  .pg-footer-nav {
    grid-template-columns: 1fr 1fr;
  }
}

.pg-footer-nav__list {
  display: grid;
  gap: 1rem;
}
.pg-footer-nav__list a {
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-dark);
  text-decoration: none;
}
.pg-footer-nav__list a:hover {
  text-decoration: none;
}

.creds {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--padding-md);
}
@media (min-width: 768px) {
  .creds {
    justify-content: center;
    gap: var(--padding-lg);
  }
}

.cred {
  display: block;
  max-width: 8rem;
  max-height: 6rem;
}
@media (min-width: 768px) {
  .cred {
    max-width: 16rem;
    max-height: 10rem;
  }
}

.cred-link {
  display: block;
}

@media (min-width: 768px) {
  .copyright {
    text-align: center;
  }
}

.title, .search-form__fields input[type=submit], .search-form__fields input[type=text] {
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-dark);
}

.title--xxl {
  font-size: var(--fs-fluid-xxl);
  line-height: 1.1;
}

.title--xl, .search-form__fields input[type=text] {
  font-size: var(--fs-fluid-xl);
  line-height: var(--line-height-sm);
}

.title--lg, .search-form__fields input[type=submit] {
  font-size: var(--fs-fluid-lg);
  line-height: var(--line-height-sm);
}

.title--md {
  font-size: var(--fs-fluid-md);
  line-height: var(--line-height-sm);
}

.title--sm {
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-sm);
}

.supporting-text, .content-carousel-text p, .hero-content p {
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
}

.feed-text, .content-feed__item p {
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
}

.text-red {
  color: var(--color-brand-red);
}

.text-red-mid {
  color: var(--color-brand-red-mid);
}

.text-orange {
  color: var(--color-brand-orange);
}

.text-purple {
  color: var(--color-brand-purple);
}

.text-white, .search-form__fields input[type=submit], .search-form__fields input[type=text] {
  color: white;
}
.text-white a, .search-form__fields input[type=submit] a, .search-form__fields input[type=text] a {
  color: inherit;
}

.text-grey-dark {
  color: var(--color-grey-dark);
}

lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  max-width: 100%;
}

/* gradient */
lite-youtube::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}

lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
  display: block;
  width: 68px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 1;
  background-color: transparent;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: none;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.login-form {
  padding: var(--padding-md);
  background-color: white;
  box-shadow: var(--feed-item-shadow);
}

.tml-field-wrap + .tml-field-wrap {
  margin: var(--padding-md) 0 0;
}

.tml-label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-family: var(--heading-font);
  color: var(--color-grey-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
}

.tml-field-wrap input[type=text],
.tml-field-wrap input[type=number],
.tml-field-wrap input[type=url],
.tml-field-wrap input[type=password],
.tml-field-wrap input[type=email],
.tml-field-wrap input[type=tel],
.tml-field-wrap input[type=date],
.tml-field-wrap select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);
  font-size: var(--fs-fluid-sm);
}
.tml-field-wrap input[type=text]:focus,
.tml-field-wrap input[type=number]:focus,
.tml-field-wrap input[type=url]:focus,
.tml-field-wrap input[type=password]:focus,
.tml-field-wrap input[type=email]:focus,
.tml-field-wrap input[type=tel]:focus,
.tml-field-wrap input[type=date]:focus,
.tml-field-wrap select:focus {
  outline: none;
  border-color: var(--color-brand-red);
}

.tml-rememberme-wrap {
  margin: var(--padding-sm) 0 0;
}
.tml-rememberme-wrap label {
  display: inline;
  margin: 0;
}

.tml-errors {
  padding: var(--padding-xs);
  margin: 0 0 var(--padding-xs);
  font-size: var(--fs-fluid-xs);
  color: white;
  list-style-type: none;
  background-color: var(--color-error);
}

.tml-error {
  color: white;
}
.tml-error strong {
  font-weight: inherit;
  text-decoration: underline;
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
  margin: var(--padding-md) 0 0;
  list-style-type: none;
}
.tml-links a {
  font-size: var(--fs-fluid-xs);
  color: var(--color-grey-dark);
}
.tml-links a:hover {
  color: black;
}

.no-flexbox-gap .tml-links li + .tml-links li {
  margin: var(--padding-xs) 0 0;
}

.tml-messages {
  margin: 0 0 var(--padding-md);
  padding: var(--padding-xs);
  list-style-type: none;
  background-color: var(--color-success);
}

.tml-message {
  color: white;
}
.tml-message a {
  color: inherit;
}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.3333333333%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: 1.4285714286%;
  height: 10px;
  float: left;
}

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */
.btn, .form-row input[type=submit], .newsletter-form__row input[type=submit], .tml-button {
  display: inline-block;
  height: 4.5rem;
  padding: 0 var(--padding-sm);
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  text-decoration: none;
  line-height: 4.5rem;
  letter-spacing: var(--kerning-lg);
  text-align: center;
  border-radius: var(--btn-radius);
}
@media (min-width: 1024px) {
  .btn, .form-row input[type=submit], .newsletter-form__row input[type=submit], .tml-button {
    font-size: var(--font-size-xs);
  }
}
.btn:hover, .form-row input[type=submit]:hover, .newsletter-form__row input[type=submit]:hover, .tml-button:hover {
  text-decoration: underline;
}

input[type=submit].btn, .form-row input[type=submit], .newsletter-form__row input[type=submit], input[type=submit].tml-button,
button.btn,
button.tml-button {
  border: none;
  cursor: pointer;
}

.btn--red-fade, .newsletter-form__row input[type=submit] {
  color: var(--color-brand-red);
  background-color: var(--color-brand-red-fade);
}
.btn--red-fade:hover, .newsletter-form__row input[type=submit]:hover {
  color: white;
  background-color: var(--color-brand-red);
}

.btn--purple-fade, .featured-content-row:nth-child(even) .btn, .featured-content-row:nth-child(even) .tml-button, .featured-content-row:nth-child(even) .newsletter-form__row input[type=submit], .newsletter-form__row .featured-content-row:nth-child(even) input[type=submit], .featured-content-row:nth-child(even) .form-row input[type=submit], .form-row .featured-content-row:nth-child(even) input[type=submit], .form-row input[type=submit], .tml-button {
  color: var(--color-brand-purple);
  background-color: var(--color-brand-purple-fade);
}
.btn--purple-fade:hover, .featured-content-row:nth-child(even) .btn:hover, .featured-content-row:nth-child(even) .tml-button:hover, .featured-content-row:nth-child(even) .newsletter-form__row input[type=submit]:hover, .newsletter-form__row .featured-content-row:nth-child(even) input[type=submit]:hover, .form-row input[type=submit]:hover, .tml-button:hover {
  color: white;
  background-color: var(--color-brand-purple);
}

.btn--white, .featured-content-row:nth-child(odd) .btn, .featured-content-row:nth-child(odd) .tml-button, .featured-content-row:nth-child(odd) .newsletter-form__row input[type=submit], .newsletter-form__row .featured-content-row:nth-child(odd) input[type=submit], .featured-content-row:nth-child(odd) .form-row input[type=submit], .form-row .featured-content-row:nth-child(odd) input[type=submit] {
  color: var(--color-brand-purple);
  background-color: rgba(255, 255, 255, 0.9);
}
.btn--white:hover, .featured-content-row:nth-child(odd) .btn:hover, .featured-content-row:nth-child(odd) .tml-button:hover, .featured-content-row:nth-child(odd) .newsletter-form__row input[type=submit]:hover, .newsletter-form__row .featured-content-row:nth-child(odd) input[type=submit]:hover, .featured-content-row:nth-child(odd) .form-row input[type=submit]:hover, .form-row .featured-content-row:nth-child(odd) input[type=submit]:hover {
  background-color: white;
}

.arrow-icon-btn {
  display: block;
  width: 2rem;
  height: 2rem;
}
@media (min-width: 768px) {
  .arrow-icon-btn {
    width: 4rem;
    height: 4rem;
  }
}
.arrow-icon-btn svg {
  fill: currentColor;
}

.arrow-icon-btn--red {
  color: var(--color-brand-red);
}
.arrow-icon-btn--red:hover {
  color: var(--color-grey-dark);
}

.text-btn {
  display: inline-block;
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  letter-spacing: var(--kerning-lg);
}

.text-btn--white {
  color: white;
}
.text-btn--white:hover {
  opacity: 0.75;
}

.text-btn--red {
  color: var(--color-brand-red);
}
.text-btn--red:hover {
  opacity: 0.75;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-sm);
}

.hero-outer {
  position: relative;
  background: linear-gradient(0, rgb(247, 247, 247) 0%, rgb(240, 240, 240) 100%);
}
@media (min-width: 1280px) {
  .hero-outer:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4rem;
    background-color: white;
  }
}
.hero-outer .hero {
  padding: var(--padding-lg) 0;
}
@media (min-width: 1280px) {
  .hero-outer .hero {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .hero-outer .hero-imgs {
    transform: translateY(-2rem);
  }
}
.hero-outer > .inner {
  position: relative;
  z-index: 2;
}

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .hero {
    flex-direction: row;
    align-items: flex-start;
  }
}

.hero-content {
  order: 2;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media (min-width: 768px) {
  .hero-content {
    order: 1;
    width: 60%;
    padding: var(--padding-lg) var(--padding-lg) var(--padding-xl) 0;
    margin: 0;
  }
}
.hero-content p + p {
  margin: var(--padding-xs) 0 0;
}
.hero-content p a {
  color: var(--color-grey-dark);
}
.hero-content p a:hover {
  color: var(--color-brand-red);
}
.hero-content p + .btn-group {
  margin: var(--padding-md) 0 0;
}

.hero-content-title {
  position: relative;
}

.hero-imgs {
  order: 1;
  width: 35rem;
  height: 30rem;
}
@media (min-width: 768px) {
  .hero-imgs {
    order: 2;
    left: 0;
    position: relative;
    width: 60%;
    height: auto;
  }
}
.hero-imgs:after {
  content: "";
  position: absolute;
  z-index: 5;
  top: 15rem;
  right: -5rem;
  transform: translateY(-50%);
  aspect-ratio: 1/1;
  width: 20rem;
  border-radius: 100%;
  mix-blend-mode: multiply;
  transition: transform 1s ease-out;
}
@media (min-width: 768px) {
  .hero-imgs:after {
    top: 50%;
    width: 50%;
  }
}

.hero-imgs__img {
  border-radius: 100%;
}

.hero-imgs__img--1 {
  position: relative;
  z-index: 1;
}

.hero-imgs--int svg {
  display: block;
  width: 75%;
  height: auto;
  fill: rgba(255, 255, 255, 0.5);
}
@media (min-width: 768px) {
  .hero-imgs--int svg {
    display: none;
  }
}
.hero-imgs--int img {
  display: block;
  width: auto;
  height: 30rem;
}
@media (min-width: 768px) {
  .hero-imgs--int img {
    display: block;
    width: 75%;
    height: auto;
  }
}
.hero-imgs--int:after {
  background: url("img/rradar-1.svg");
  background-size: 100% auto;
  background-position: center center;
}

.hero--home {
  align-items: flex-end;
  padding: var(--padding-md) 0 0;
}
@media (min-width: 768px) {
  .hero--home {
    padding: var(--padding-lg) 0 0;
  }
}

@media (min-width: 768px) {
  .hero--home .hero-content {
    order: 1;
    width: 75%;
    padding: var(--padding-lg) var(--padding-lg) 0 0;
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .hero--home .hero-content {
    width: 60%;
  }
}

.hero-content-text-outer {
  position: relative;
  overflow: hidden;
}

.hero-content-text {
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
}

.hero-content-text:not(:first-child) {
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateY(5rem);
}

.hero-imgs--home img {
  display: block;
  width: auto;
  height: 30rem;
}
@media (min-width: 768px) {
  .hero-imgs--home img {
    display: block;
    width: 75%;
    height: auto;
  }
}
.hero-imgs--home .hero-imgs__img:not(:first-of-type) {
  position: absolute;
  top: var(--padding-md);
}
@media (min-width: 768px) {
  .hero-imgs--home .hero-imgs__img:not(:first-of-type) {
    top: 0;
  }
}
.hero-imgs--home:after {
  background-color: var(--color-brand-red);
}
.hero-imgs--home .hero-imgs__img--1 {
  z-index: 4;
}
.hero-imgs--home .hero-imgs__img--2 {
  z-index: 3;
}
.hero-imgs--home .hero-imgs__img--3 {
  z-index: 2;
}

.hero.is-scrolling .hero-imgs:after {
  transform: translateY(-65%);
}

.cta--lg {
  position: relative;
  padding: var(--padding-xl) 0;
  overflow: hidden;
}
.cta--lg .inner {
  position: relative;
  z-index: 3;
}
.cta--lg > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 70%;
  opacity: 0.5;
  fill: var(--color-brand-red);
}
@media (min-width: 768px) {
  .cta--lg > svg {
    opacity: 1;
  }
}

.cta--std {
  background-color: var(--color-brand-red-dark);
}
.cta--std svg {
  right: -20%;
}
.cta--std:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20%;
  transform: translateY(-50%);
  z-index: 1;
  aspect-ratio: 1/1;
  width: 70%;
  background: radial-gradient(circle, rgb(158, 23, 51) 0%, rgb(100, 19, 51) 50%);
  background-size: 100% auto;
  background-position: center center;
  opacity: 0.5;
}
@media (min-width: 768px) {
  .cta--std:after {
    opacity: 1;
  }
}

.cta--alt {
  background-color: var(--color-brand-red-mid);
}
.cta--alt svg {
  left: -20%;
}
.cta--alt:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -20%;
  transform: translateY(-50%);
  z-index: 1;
  aspect-ratio: 1/1;
  width: 70%;
  background: radial-gradient(circle, rgb(201, 0, 0) 0%, rgb(158, 23, 51) 50%);
  background-size: 100% auto;
  background-position: center center;
  opacity: 0.5;
}
@media (min-width: 768px) {
  .cta--alt:after {
    opacity: 1;
  }
}
.cta--alt .cta {
  justify-content: flex-end;
}

.cta {
  position: relative;
  display: flex;
}

.cta-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.cta--lg .cta-text {
  gap: var(--padding-md);
}
@media (min-width: 768px) {
  .cta--lg .cta-text {
    width: calc(50% - var(--padding-lg));
  }
}

.cta--sm {
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: var(--padding-lg) 2.5% var(--padding-lg) var(--padding-lg);
  text-decoration: none;
  color: white;
}
.cta--sm:hover .title, .cta--sm:hover .search-form__fields input[type=submit], .search-form__fields .cta--sm:hover input[type=submit], .cta--sm:hover .search-form__fields input[type=text], .search-form__fields .cta--sm:hover input[type=text] {
  text-decoration: underline;
}
.cta--sm:hover .cta-arrow {
  transform: var(--arrow-hover);
}
.cta--sm .cta-text {
  position: relative;
  z-index: 2;
  gap: var(--padding-sm);
  padding: 0 var(--padding-xl) 0 0;
}
.cta--sm > svg {
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translateY(-50%);
  z-index: 1;
  width: 30%;
  fill: white;
  opacity: 0.2;
}

.cta-arrow {
  width: 5%;
  height: auto;
  fill: white;
}

.cta--red {
  background-color: var(--color-brand-red);
}

.cta--red-mid {
  background-color: var(--color-brand-red-mid);
}

.cta--red-dark {
  background-color: var(--color-brand-red-dark);
}

.cta--purple {
  background-color: var(--color-brand-purple);
}

.cta-outer.cta--lg.in-viewport .ring-1 {
  animation: rradar 0.35s ease-in-out 1, rradar 0.35s ease-in-out 1.5s 1;
}
.cta-outer.cta--lg.in-viewport .ring-2 {
  animation: rradar 0.33s ease-in-out 0.35s 1, rradar 0.33s ease-in-out 1.85s 1;
}
.cta-outer.cta--lg.in-viewport .ring-3 {
  animation: rradar 0.31s ease-in-out 0.54s 1, rradar 0.31s ease-in-out 2.04s 1;
}
.cta-outer.cta--lg.in-viewport .ring-4 {
  animation: rradar 0.29s ease-in-out 0.73s 1, rradar 0.29s ease-in-out 2.23s 1;
}
.cta-outer.cta--lg.in-viewport .ring-5 {
  animation: rradar 0.27s ease-in-out 0.9s 1, rradar 0.27s ease-in-out 2.4s 1;
}
.cta-outer.cta--lg.in-viewport .ring-6 {
  animation: rradar 0.25s ease-in-out 1.05s 1, rradar 0.25s ease-in-out 2.55s 1;
}
.cta-outer.cta--lg.in-viewport .ring-7 {
  animation: rradar 0.23s ease-in-out 1.18s 1, rradar 0.23s ease-in-out 2.68s 1;
}
.cta-outer.cta--lg.in-viewport .ring-8 {
  animation: rradar 0.21s ease-in-out 1.29s 1, rradar 0.21s ease-in-out 2.79s 1;
}
.cta-outer.cta--lg.in-viewport .ring-9 {
  animation: rradar 0.19s ease-in-out 1.38s 1, rradar 0.19s ease-in-out 2.88s 1;
}
.cta-outer.cta--lg.in-viewport .ring-10 {
  animation: rradar 0.17s ease-in-out 1.45s 1, rradar 0.17s ease-in-out 2.95s 1;
}

.cta-outer.in-viewport .cta--sm .ring-1 {
  animation: rradar 0.2s ease-in-out 1, rradar 0.2s ease-in-out 0.8s 1;
}
.cta-outer.in-viewport .cta--sm .ring-2 {
  animation: rradar 0.2s ease-in-out 0.2s 1, rradar 0.2s ease-in-out 1s 1;
}
.cta-outer.in-viewport .cta--sm .ring-3 {
  animation: rradar 0.2s ease-in-out 0.4s 1, rradar 0.2s ease-in-out 1.2s 1;
}
.cta-outer.in-viewport .cta--sm .ring-4 {
  animation: rradar 0.2s ease-in-out 0.6s 1, rradar 0.2s ease-in-out 1.4s 1;
}

@keyframes rradar {
  100% {
    opacity: 0;
  }
}
.quicklinks {
  display: grid;
  grid-gap: var(--padding-sm);
}

.quicklinks-text {
  display: grid;
  gap: var(--padding-sm);
  width: max(60%, 30rem);
}

.quicklink {
  display: grid;
  grid-gap: var(--padding-sm);
  padding: var(--padding-sm) var(--padding-sm) var(--padding-md);
  background-color: var(--color-grey-light);
  border-bottom: 3px solid var(--color-grey);
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-md);
  text-decoration: none;
  color: var(--color-grey-dark);
  letter-spacing: var(--kerning-lg);
}
.quicklink:hover {
  text-decoration: underline;
  border-color: var(--color-brand-grey-dark);
}

.quicklink__icon {
  display: block;
  width: 2rem;
  height: 2rem;
}
@media (min-width: 768px) {
  .quicklink__icon {
    width: 4rem;
    height: 4rem;
  }
}

@media (min-width: 640px) {
  .inner--xl > .quicklinks {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .inner--xl > .quicklinks {
    grid-template-columns: repeat(4, 1fr);
  }
}

.topics {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.topic {
  display: block;
  height: 2.5rem;
  padding: 0 1rem;
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-decoration: none;
}
.topic:hover {
  text-decoration: underline;
}
.topic.mixitup-control-active {
  color: white;
}

.topic--grey-dark {
  background-color: var(--color-grey-light);
  color: var(--color-grey-dark);
}
.topic--grey-dark.mixitup-control-active {
  background-color: var(--color-grey-dark);
}

.topic--red-mid {
  background-color: var(--color-brand-red-mid-fade);
  color: var(--color-brand-red-mid);
}
.topic--red-mid.mixitup-control-active {
  background-color: var(--color-brand-red-mid);
}

.topic--orange {
  background-color: var(--color-brand-orange-fade);
  color: var(--color-brand-orange);
}
.topic--orange.mixitup-control-active {
  background-color: var(--color-brand-orange);
}

.topic--purple {
  background-color: var(--color-brand-purple-fade);
  color: var(--color-brand-purple);
}
.topic--purple.mixitup-control-active {
  background-color: var(--color-brand-purple);
}

.topic--red {
  background-color: var(--color-brand-red-fade);
  color: var(--color-brand-red);
}
.topic--red.mixitup-control-active {
  background-color: var(--color-brand-red);
}

.topic-quicklinks {
  display: grid;
  gap: var(--padding-sm);
}
@media (min-width: 640px) {
  .topic-quicklinks {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .topic-quicklinks {
    grid-template-columns: repeat(4, 1fr);
  }
}

.topic-quicklink {
  position: relative;
  display: block;
  height: 100%;
  padding: var(--padding-md) var(--padding-sm) var(--padding-xl);
  border-bottom: 3px solid;
  text-decoration: none;
}
.topic-quicklink svg {
  position: absolute;
  bottom: var(--padding-sm);
  left: var(--padding-sm);
  z-index: 1;
  width: min(10%, 3rem);
}
.topic-quicklink:hover {
  border-color: var(--color-grey-dark);
}
.topic-quicklink:hover .title, .topic-quicklink:hover .search-form__fields input[type=submit], .search-form__fields .topic-quicklink:hover input[type=submit], .topic-quicklink:hover .search-form__fields input[type=text], .search-form__fields .topic-quicklink:hover input[type=text] {
  text-decoration: underline;
}
.topic-quicklink:hover svg {
  transform: var(--arrow-hover);
}
.topic-quicklink .supporting-text, .topic-quicklink .content-carousel-text p, .content-carousel-text .topic-quicklink p, .topic-quicklink .hero-content p, .hero-content .topic-quicklink p {
  color: var(--color-grey-dark);
}

.topic-quicklink--red-mid {
  background-color: #f6e8eb;
  border-color: var(--color-brand-red-mid);
}
.topic-quicklink--red-mid .title, .topic-quicklink--red-mid .search-form__fields input[type=submit], .search-form__fields .topic-quicklink--red-mid input[type=submit], .topic-quicklink--red-mid .search-form__fields input[type=text], .search-form__fields .topic-quicklink--red-mid input[type=text] {
  color: var(--color-brand-red-mid);
}

.topic-quicklink--orange {
  background-color: #fff2e9;
  border-color: var(--color-brand-orange);
}
.topic-quicklink--orange .title, .topic-quicklink--orange .search-form__fields input[type=submit], .search-form__fields .topic-quicklink--orange input[type=submit], .topic-quicklink--orange .search-form__fields input[type=text], .search-form__fields .topic-quicklink--orange input[type=text] {
  color: var(--color-brand-orange);
}

.topic-quicklink--purple {
  background-color: #ede8ef;
  border-color: var(--color-brand-purple);
}
.topic-quicklink--purple .title, .topic-quicklink--purple .search-form__fields input[type=submit], .search-form__fields .topic-quicklink--purple input[type=submit], .topic-quicklink--purple .search-form__fields input[type=text], .search-form__fields .topic-quicklink--purple input[type=text] {
  color: var(--color-brand-purple);
}

.topic-quicklink--red {
  background-color: #fae6e6;
  border-color: var(--color-brand-red);
}
.topic-quicklink--red .title, .topic-quicklink--red .search-form__fields input[type=submit], .search-form__fields .topic-quicklink--red input[type=submit], .topic-quicklink--red .search-form__fields input[type=text], .search-form__fields .topic-quicklink--red input[type=text] {
  color: var(--color-brand-red);
}

.testimonial {
  position: relative;
}
@media (min-width: 768px) {
  .testimonial {
    display: flex;
    background-color: var(--color-grey-light);
  }
}

@media (min-width: 768px) {
  .testimonial-quote-outer {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
}

.testimonial-quote-inner {
  padding: var(--padding-md);
}
@media (min-width: 768px) {
  .testimonial-quote-inner {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(var(--mq-lg) / 2);
    padding: var(--padding-md) 0 var(--padding-md) var(--padding-md);
  }
}

.testimonial-img-outer {
  display: none;
}
@media (min-width: 768px) {
  .testimonial-img-outer {
    display: block;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .testimonial-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.testimonial-quote {
  display: grid;
  grid-gap: var(--padding-md);
  padding: var(--padding-md);
  background-color: white;
  box-shadow: var(--feed-item-shadow);
}
@media (min-width: 768px) {
  .testimonial-quote {
    position: relative;
  }
  .testimonial-quote:after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    width: 4rem;
    height: 100%;
    background-color: white;
  }
}

.testimonial-quote__icon {
  display: block;
  width: 6rem;
  height: 6rem;
  padding: 0.75rem;
  border-radius: 100%;
  background-color: var(--color-brand-red-fade);
}
.testimonial-quote__icon div {
  width: 4.5rem;
  height: 4.5rem;
  padding: 0.75rem;
  border-radius: 100%;
  background-color: var(--color-brand-red);
}
.testimonial-quote__icon svg {
  width: 3rem;
  height: 3rem;
  fill: white;
}

.newsletter-form-outer {
  position: relative;
  padding: var(--padding-xl) 0;
  overflow: hidden;
  background-color: white;
}
.newsletter-form-outer .inner {
  position: relative;
  z-index: 3;
}
@media (min-width: 1024px) {
  .newsletter-form-outer:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 60%;
    height: 100%;
    background: url("img/newsletter-signup-bg-new.jpg") no-repeat right center;
    background-size: auto 100%;
    opacity: 0.5;
  }
}
@media (min-width: 1024px) {
  .newsletter-form-outer:after {
    max-width: var(--mq-md);
    opacity: 1;
  }
}
.newsletter-form-outer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  width: 100%;
  height: 10rem;
  background: linear-gradient(180deg, rgb(247, 247, 247) 0%, rgba(247, 247, 247, 0) 100%);
  mix-blend-mode: multiply;
}

@media (min-width: 1024px) {
  .newsletter-form-inner {
    display: flex;
    justify-content: flex-end;
  }
}

.newsletter-form {
  display: grid;
  gap: var(--padding-sm);
}
@media (min-width: 1024px) {
  .newsletter-form {
    width: calc(50% - var(--padding-lg));
  }
}

.newsletter-form__row + .newsletter-form__row {
  margin: var(--padding-md) 0 0;
}

.newsletter-form__label {
  display: block;
  margin: 0 0 var(--padding-xs);
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
  color: var(--color-grey-dark);
  letter-spacing: var(--kerning-lg);
}

.newsletter-form__row input[type=email] {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);
  font-size: var(--fs-fluid-xs);
}
.newsletter-form__row input[type=email]:focus {
  outline: none;
  border-color: var(--color-brand-red);
}

.newsletter-form__row .wpcf7-checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs) var(--padding-md);
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-md);
  color: var(--color-grey-dark);
}
.newsletter-form__row .wpcf7-checkbox .wpcf7-list-item label {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.newsletter-form__row .wpcf7-checkbox input[type=checkbox] {
  border: none;
  transform: scale(1.5);
}

.form-row + .form-row {
  margin: var(--padding-md) 0 0;
}

.form-row + [data-class=wpcf7cf_group] {
  margin: var(--padding-md) 0;
}

[data-class=wpcf7cf_group] + .form-row {
  margin: var(--padding-md) 0;
}

.form-row__label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-family: var(--heading-font);
  color: var(--color-grey-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
}

.form-row input[type=text],
.form-row input[type=number],
.form-row input[type=url],
.form-row input[type=password],
.form-row input[type=email],
.form-row input[type=tel],
.form-row input[type=date],
.form-row select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);
  font-size: var(--fs-fluid-xs);
}
.form-row input[type=text]:focus,
.form-row input[type=number]:focus,
.form-row input[type=url]:focus,
.form-row input[type=password]:focus,
.form-row input[type=email]:focus,
.form-row input[type=tel]:focus,
.form-row input[type=date]:focus,
.form-row select:focus {
  outline: none;
  border-color: var(--color-brand-red);
}

.form-row input[type=date] {
  text-transform: uppercase;
}

.form-row textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);
  font-size: var(--fs-fluid-sm);
}
.form-row textarea:focus {
  outline: none;
  border-color: var(--color-brand-red);
}

.wpcf7-checkbox,
.wpcf7-radio {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
}

.wpcf7-checkbox .wpcf7-list-item label,
.wpcf7-radio .wpcf7-list-item label {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.form-row .wpcf7-checkbox .wpcf7-list-item-label,
.form-row .wpcf7-radio .wpcf7-list-item-label {
  font-family: var(--heading-font);
  color: var(--color-grey-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
}

.wpcf7-acceptance {
  display: block;
  width: 100%;
  padding: var(--padding-xs);
  border: 1px solid var(--color-brand-red-fade);
}

.wpcf7-acceptance input {
  margin: 0 1rem 0 0;
}

.wpcf7-acceptance .wpcf7-list-item-label {
  font-size: var(--fs-fluid-xs);
  color: var(--color-brand-red-mid);
  line-height: var(--line-height-md);
}
.wpcf7-acceptance .wpcf7-list-item-label a {
  color: inherit;
}

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin: 0;
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  color: var(--color-brand-red);
  letter-spacing: var(--kerning-sm);
  background-color: #FAE5E5;
}

.wpcf7-response-output {
  display: block;
  width: 100%;
  padding: 1rem;
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  font-size: var(--fs-fluid-sm);
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;
  background-color: var(--color-error);
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;
  background-color: var(--color-success);
}

.bg-grey-light .form-row input[type=text],
.bg-grey-light .form-row input[type=number],
.bg-grey-light .form-row input[type=url],
.bg-grey-light .form-row input[type=password],
.bg-grey-light .form-row input[type=email],
.bg-grey-light .form-row input[type=tel],
.bg-grey-light .form-row input[type=date],
.bg-grey-light .form-row select,
.bg-grey-light .form-row textarea {
  background-color: white;
}

.breadcrumbs {
  padding: var(--padding-sm) 0;
  background-color: var(--color-grey-light);
}

.breadcrumb__items {
  font-family: var(--heading-font);
  font-size: 1.4rem;
  letter-spacing: var(--kerning-lg);
  color: var(--color-brand-red);
}
.breadcrumb__items a {
  color: var(--color-brand-red);
}

.two-col-text-outer .title, .two-col-text-outer .search-form__fields input[type=submit], .search-form__fields .two-col-text-outer input[type=submit], .two-col-text-outer .search-form__fields input[type=text], .search-form__fields .two-col-text-outer input[type=text] {
  width: max(60%, 30rem);
}

@media (min-width: 768px) {
  .two-col-text {
    columns: 2;
    column-gap: var(--padding-lg);
    break-inside: avoid;
  }
}

.std-content {
  width: 100%;
}
.std-content > *:not(h2, h3, h4, h5, h6) + * {
  margin: var(--padding-sm) 0 0;
}
.std-content h2:not(:first-child), .std-content h3:not(:first-child), .std-content h4:not(:first-child), .std-content h5:not(:first-child), .std-content h6:not(:first-child) {
  margin: var(--padding-md) 0 var(--padding-xs);
}
.std-content h2:first-child, .std-content h3:first-child, .std-content h4:first-child, .std-content h5:first-child, .std-content h6:first-child {
  margin: 0 0 var(--padding-xs);
}
.std-content h2, .std-content h3, .std-content h4, .std-content h5 {
  font-family: var(--heading-font);
  line-height: var(--line-height-md);
  color: var(--color-grey-dark);
  letter-spacing: var(--kerning-lg);
}
.std-content h2 {
  font-size: var(--fs-fluid-lg);
}
.std-content h3 {
  font-size: var(--fs-fluid-md);
}
.std-content h4 {
  font-size: var(--fs-fluid-sm);
}
.std-content h5 {
  font-size: var(--fs-fluid-sm);
}
.std-content p a, .std-content ul a, .std-content ol a {
  color: var(--color-brand-red);
  text-decoration: underline;
}
.std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
  color: black;
}
.std-content p > strong, .std-content ul > strong, .std-content ol > strong {
  font-weight: var(--font-weight-bold);
  color: black;
}
.std-content p > em, .std-content ul > em, .std-content ol > em {
  font-style: italic;
}
.std-content p {
  line-height: var(--line-height-md);
}
.std-content sup {
  font-size: 75%;
}
.std-content ul li::marker, .std-content ol li::marker {
  color: var(--color-brand-red);
}
.std-content ul li > *:last-child, .std-content ol li > *:last-child {
  padding: 0 0 var(--padding-sm);
}
.std-content ul {
  list-style-type: disc;
  padding-left: var(--padding-sm);
}
.std-content ol {
  list-style-type: decimal;
  padding-left: var(--padding-sm);
  counter-reset: item;
}
.std-content ul ul {
  margin: 1rem 0 0 3rem;
  list-style-type: circle;
}
.std-content li {
  line-height: var(--line-height-lg);
}
.std-content li + li {
  margin-top: 1rem;
}
.std-content img {
  display: block;
  width: 100%;
  height: auto;
  margin: var(--padding-md) auto;
}
.std-content a {
  color: inherit;
  text-decoration: underline;
}
.std-content a:hover {
  color: black;
}
.std-content address {
  font-style: normal;
}

.std-content img.alignleft,
.std-content img.alignright {
  width: 50%;
  margin: var(--padding-md) auto;
}
@media (min-width: 640px) {
  .std-content img.alignleft,
.std-content img.alignright {
    width: 20%;
    clear: both;
    margin-top: 0.5rem;
    margin-bottom: var(--padding-sm);
  }
}
@media (min-width: 1280px) {
  .std-content img.alignleft,
.std-content img.alignright {
    width: 25%;
  }
}
@media (min-width: 640px) {
  .std-content img.alignleft {
    float: left;
    margin-right: var(--padding-md);
  }
}
@media (min-width: 640px) {
  .std-content img.alignright {
    float: right;
    margin-left: var(--padding-md);
  }
}
.std-content img.alignnone {
  clear: both;
}
.std-content .wp-caption {
  width: 100% !important;
  height: auto;
  margin: var(--padding-md) auto;
}
.std-content .wp-caption img {
  margin: 0;
}
.std-content p.wp-caption-text {
  margin: 0 auto;
  padding: 1rem;
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-sm);
  text-align: center;
  color: var(--color-grey-dark);
  background-color: var(--color-grey-light);
}

.std-content iframe,
.video-embed iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.std-content .video-embed {
  margin: var(--padding-lg) 0;
}
.std-content .video-embed:last-child {
  margin: var(--padding-lg) 0 0;
}

.std-content table {
  table-layout: fixed;
  width: 100%;
  margin: var(--padding-lg) 0;
  border-collapse: collapse;
  border-spacing: 0px;
  vertical-align: top;
  background-color: white;
}
.std-content table:first-child {
  margin: 0 0 var(--padding-lg);
}
.std-content table th, .std-content table td {
  vertical-align: top;
  padding: 1.5rem 1rem;
}
.std-content table th {
  border-bottom: 3px solid var(--color-grey-light);
  font-size: 1.6rem;
  font-weight: var(--font-weight-bold);
}
.std-content table tr:nth-child(even) td {
  background-color: var(--color-grey-light);
}
.std-content table td {
  font-size: 1.4rem;
  line-height: var(--line-height-sm);
}
.std-content table .tier {
  display: block;
  font-size: var(--fs-fluid-md);
  font-weight: var(--font-weight-bold);
}
.std-content table .price {
  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-lg);
}
.std-content table .duration {
  font-weight: 300;
}
.std-content table .italic {
  font-style: italic;
}

@media (min-width: 768px) {
  .content-col.std-content table {
    width: 100%;
    transform: none;
  }
}

.tablepress-id-packages-1 td:first-child {
  font-weight: var(--font-weight-bold);
}

.content-feed-outer {
  position: relative;
  padding: var(--padding-xl) 0 0;
}
.content-feed-outer .inner,
.content-feed-outer .overflow {
  position: relative;
  z-index: 2;
}
.content-feed-outer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
}
@media (min-width: 1536px) {
  .content-feed-outer:after {
    left: var(--padding-xl);
    width: calc(100% - var(--padding-xl));
  }
}

.content-feed-outer--grey-light:after {
  background-color: var(--color-grey-light);
}

.content-feed-outer--red-mid:after {
  background-color: var(--color-brand-red-mid);
}

.content-feed-outer--red-mid.is-even:after {
  background-color: var(--color-brand-red-dark);
}

.content-feed-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}

.content-feed-header__text {
  display: grid;
  gap: var(--padding-md);
  width: max(60%, 30rem);
}

.content-feed-title {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
}

.content-feed-title__icon {
  display: block;
  width: 2rem;
  height: 2rem;
}
@media (min-width: 768px) {
  .content-feed-title__icon {
    width: 4rem;
    height: 4rem;
  }
}

.topics-cta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-sm);
}

.content-feed__item {
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-sm) 0;
  background-color: white;
  box-shadow: var(--feed-item-shadow);
}
.content-feed__item p + p {
  margin: 0.5rem 0 0;
}

.content-feed__item__img-outer {
  aspect-ratio: 3/2;
}

.content-feed__item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content-feed--awards .content-feed__item__img-outer {
  display: grid;
  place-items: center;
  height: 20rem;
  aspect-ratio: unset;
}

.content-feed--awards .content-feed__item__img {
  max-width: 100%;
  max-height: 20rem;
  width: auto;
  height: auto;
  object-fit: contain;
}

.content-feed--fixed,
.content-feed--locations {
  display: grid;
  gap: var(--padding-xs);
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 30rem), 1fr));
}

.content-feed--slider {
  display: flex;
  gap: var(--padding-xs);
  padding: 0.5rem;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content-feed--slider::-webkit-scrollbar {
  display: none;
}
.content-feed--slider .content-feed__item {
  flex-shrink: 0;
  width: 30rem;
}
@media (min-width: 768px) {
  .content-feed--slider .content-feed__item {
    width: 40rem;
  }
}

.content-feed__item__link {
  flex: 1;
  position: relative;
  display: block;
  padding: 0 0 var(--padding-xl);
  text-decoration: none;
}
.content-feed__item__link:hover .title--md {
  text-decoration: underline;
}
.content-feed__item__link:hover svg {
  transform: var(--arrow-hover);
}
.content-feed__item__link .title, .content-feed__item__link .search-form__fields input[type=submit], .search-form__fields .content-feed__item__link input[type=submit], .content-feed__item__link .search-form__fields input[type=text], .search-form__fields .content-feed__item__link input[type=text] {
  color: var(--color-grey-dark);
}
.content-feed__item__link .supporting-text, .content-feed__item__link .content-carousel-text p, .content-carousel-text .content-feed__item__link p, .content-feed__item__link .hero-content p, .hero-content .content-feed__item__link p {
  color: var(--color-grey-mid);
}
.content-feed__item__link svg {
  position: absolute;
  bottom: var(--padding-sm);
  left: 0;
  z-index: 1;
  display: block;
  width: min(10%, 3rem);
  fill: var(--color-brand-red);
}

.content-feed__item__label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  font-size: 1.6rem;
  color: var(--color-brand-red);
  line-height: 1;
}
.content-feed__item__label:before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-brand-red);
  border-radius: 100%;
}

.content-feed__item__label--event {
  color: var(--color-grey-dark);
}
.content-feed__item__label--event:before {
  background-color: var(--color-grey-dark);
}

.content-type-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 var(--padding-sm);
}

.content-type-title__icon {
  display: block;
  width: 2rem;
  height: 2rem;
}

.content-feed__img--no-img .title--md {
  padding: 0 0 var(--padding-xs);
  border-bottom: 1px solid var(--color-grey-mid);
}

.content-feed-controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.content-feed-control {
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  background-color: var(--color-brand-purple-fade);
  border-radius: var(--btn-radius);
  cursor: pointer;
}
.content-feed-control:hover {
  background-color: var(--color-brand-purple);
}
.content-feed-control:hover svg {
  fill: white;
}
.content-feed-control svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--color-brand-purple);
}

.content-feed-control--left {
  transform: rotate(-180deg);
}

.content-feed + .content-feed-controls {
  margin: var(--padding-md) 0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xs);
}

.pagination-pg {
  display: block;
  width: 2rem;
  height: 2rem;
  background: none;
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-mid);
  text-decoration: underline;
  text-align: center;
}
.pagination-pg:hover {
  color: var(--color-brand-red);
}
.pagination-pg.mixitup-control-active {
  color: var(--color-brand-red-mid);
}

.pagination-control {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  background: none;
}
.pagination-control:hover svg {
  fill: var(--color-brand-red);
}
.pagination-control svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--color-grey-mid);
}

.pagination-control--prev svg {
  transform: rotate(180deg);
}

.people-grid-outer {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .people-grid-outer {
    grid-template-columns: 1fr 2fr;
  }
}
@media (min-width: 1280px) {
  .people-grid-outer {
    gap: var(--padding-xl);
    grid-template-columns: 1fr 3fr;
  }
}

.people-grid-text {
  text-align: center;
}
@media (min-width: 640px) {
  .people-grid-text {
    text-align: left;
  }
}

.people-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--padding-xl);
}
.people-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
}

.people-grid__item__img-link {
  display: block;
}

.people-grid__item__img {
  display: block;
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
}

.people-grid__item__info {
  text-align: center;
}
.people-grid__item__info .title:hover, .people-grid__item__info .search-form__fields input[type=submit]:hover, .search-form__fields .people-grid__item__info input[type=submit]:hover, .people-grid__item__info .search-form__fields input[type=text]:hover, .search-form__fields .people-grid__item__info input[type=text]:hover {
  color: var(--color-brand-red);
}

.people-group + .people-group .people-grid-outer {
  padding: var(--padding-xl) 0 0;
  margin: var(--padding-xl) 0 0;
  border-top: 1px solid var(--color-grey);
}

.content-carousel-outer {
  position: relative;
  overflow: hidden;
  background-color: var(--color-brand-red-mid);
}
@media (min-width: 768px) {
  .content-carousel-outer {
    background: none;
  }
}
.content-carousel-outer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50%;
  height: 100%;
  background-color: var(--color-brand-red-mid);
}
.content-carousel-outer .inner {
  position: relative;
  z-index: 2;
  background-color: var(--color-brand-red-mid);
}

.content-carousel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-xl);
  margin: 0 auto;
}
.content-carousel:after {
  content: "";
  position: absolute;
  left: -75%;
  z-index: 1;
  width: 100%;
  aspect-ratio: 1/1;
  background-image: url("img/rradar-4.svg");
  background-position: center center;
  background-size: 100% auto;
  opacity: 0.5;
}

.content-carousel-text {
  padding: var(--padding-xl) 0 var(--padding-lg);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  position: relative;
  z-index: 2;
}
.content-carousel-text p {
  color: white;
}
.content-carousel-text p + p {
  margin: var(--padding-xs) 0 0;
}
.content-carousel-text p a {
  color: white;
}

.content-carousel--no-text {
  padding: calc(var(--padding-xl) * 1.5) 0;
}

.content-carousel__slides {
  position: relative;
  z-index: 2;
}

.content-carousel__slide {
  position: relative;
  padding: var(--padding-lg) var(--padding-md) var(--padding-xl);
}

.tns-outer {
  width: 65%;
}

.tns-item {
  opacity: 0;
  transition: 1s opacity ease-in;
}

.tns-item.tns-slide-active {
  opacity: 1;
}

.content-carousel__control {
  position: relative;
  z-index: 2;
  display: none;
  place-items: center;
  width: 5rem;
  height: 5rem;
  background: none;
}
@media (min-width: 640px) {
  .content-carousel__control {
    display: grid;
  }
}
.content-carousel__control svg {
  width: 4rem;
  height: 4rem;
  fill: white;
}
.content-carousel__control:disabled {
  opacity: 0.5;
}

.content-carousel__control--prev svg {
  transform: rotate(-180deg);
}

.content-carousel__slide--with-icon {
  position: relative;
  padding: var(--padding-lg) 0 var(--padding-lg) calc(5rem + var(--padding-md));
}
@media (min-width: 640px) {
  .content-carousel__slide--with-icon {
    padding: var(--padding-lg) calc(5rem + var(--padding-md));
  }
}

.content-carousel__slide__icon {
  position: absolute;
  top: var(--padding-lg);
  left: 0;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  background-color: white;
  border-radius: 100%;
}
.content-carousel__slide__icon img {
  display: block;
  width: 4rem;
  height: auto;
}

.content-carousel__slide__header {
  width: 75%;
}

.featured-content {
  position: relative;
}

.featured-content-row {
  position: relative;
}
.featured-content-row:nth-child(odd) {
  z-index: 2;
  padding: var(--padding-xl) 0 0;
  background-color: var(--color-brand-red-mid);
}
@media (min-width: 768px) {
  .featured-content-row:nth-child(odd) .featured-content-text {
    padding: 0 0 var(--padding-xl) 0;
  }
}
@media (min-width: 1536px) {
  .featured-content-row:nth-child(odd):after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: var(--padding-xl);
    height: 100%;
    background-color: white;
  }
}
.featured-content-row:nth-child(even) {
  z-index: 1;
  padding: var(--padding-xl) 0;
}
@media (min-width: 768px) {
  .featured-content-row:nth-child(even) .featured-content-text {
    order: 2;
    padding: 0 var(--padding-lg) 0 0;
  }
}
@media (min-width: 768px) {
  .featured-content-row:nth-child(even) .featured-content-media {
    order: 1;
  }
}
.featured-content-cols {
  display: grid;
  gap: var(--padding-xl);
}
@media (min-width: 768px) {
  .featured-content-cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

.featured-content-media .content-feed__item {
  width: 100%;
}

.featured-content-row:nth-child(odd) .std-content h2, .featured-content-row:nth-child(odd) .std-content h3, .featured-content-row:nth-child(odd) .std-content h4, .featured-content-row:nth-child(odd) .std-content h5 {
  color: white;
}
.featured-content-row:nth-child(odd) .std-content p, .featured-content-row:nth-child(odd) .std-content ul, .featured-content-row:nth-child(odd) .std-content ol {
  color: white;
}
.featured-content-row:nth-child(odd) .std-content p a, .featured-content-row:nth-child(odd) .std-content ul a, .featured-content-row:nth-child(odd) .std-content ol a {
  color: white;
}
.featured-content-row:nth-child(odd) .std-content p a:hover, .featured-content-row:nth-child(odd) .std-content ul a:hover, .featured-content-row:nth-child(odd) .std-content ol a:hover {
  color: white;
  opacity: 0.8;
}
.featured-content-row:nth-child(odd) .std-content p > strong, .featured-content-row:nth-child(odd) .std-content ul > strong, .featured-content-row:nth-child(odd) .std-content ol > strong {
  color: white;
}
.featured-content-row:nth-child(odd) .std-content p {
  line-height: var(--line-height-md);
}
.featured-content-row:nth-child(odd) .std-content ul li::marker, .featured-content-row:nth-child(odd) .std-content ol li::marker {
  color: white;
}
.featured-content-row:nth-child(odd) .std-content a {
  color: white;
  text-decoration: underline;
}
.featured-content-row:nth-child(odd) .std-content a:hover {
  color: white;
  opacity: 0.8;
}

@media (min-width: 768px) {
  .featured-content-row--post .featured-content-media {
    display: flex;
    align-items: flex-end;
    transform: translateY(10rem);
  }
}

@media (min-width: 768px) {
  .featured-content-row--post + .featured-content-row .featured-content-text {
    padding: 10rem 0 0;
  }
}

.contact-form {
  display: grid;
  gap: var(--padding-lg);
}

@media (min-width: 768px) {
  .contact-form--with-img {
    grid-template-columns: 1fr 1fr;
  }
}

.contact-form__img-outer {
  display: block;
  margin: var(--padding-lg) 0 0;
  position: relative;
}
.contact-form__img-outer:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 45%;
  right: 45%;
  aspect-ratio: 1/1;
  width: 20%;
  border-radius: 100%;
  background: url("img/rradar-1.svg");
  background-size: 100% auto;
  mix-blend-mode: multiply;
}
@media (min-width: 768px) {
  .contact-form__img-outer:after {
    bottom: 0%;
    right: 30%;
    width: 40%;
  }
}
@media (min-width: 768px) {
  .contact-form__img-outer {
    padding: 0 0 20%;
  }
}

.contact-form__logo {
  margin: 0 0 var(--padding-md);
}
.contact-form__logo svg,
.contact-form__logo img {
  display: block;
  width: auto;
  height: 5rem;
}

.contact-form__img {
  display: block;
  width: 40%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.related-content-outer {
  position: relative;
  padding: var(--padding-xl) 0;
  background-color: var(--color-brand-red-mid);
  overflow: hidden;
}
.related-content-outer:before {
  content: "";
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  aspect-ratio: 1/1;
  background-color: var(--color-brand-red-dark);
  opacity: 0.2;
  border-radius: 100%;
}
.related-content-outer:after {
  content: "";
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translateY(-50%);
  width: 75%;
  aspect-ratio: 1/1;
  background-color: var(--color-brand-red-dark);
  opacity: 0.2;
  border-radius: 100%;
}
.related-content-outer .inner {
  position: relative;
  z-index: 3;
}

.related-content {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .related-content {
    grid-template-columns: 1fr 1fr;
    gap: var(--padding-md) var(--padding-lg);
  }
}

.related-content__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-xl);
  padding: var(--padding-md);
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-md);
  line-height: var(--line-height-md);
  color: var(--color-brand-red-mid);
  text-decoration: none;
  letter-spacing: var(--kerning-lg);
  background-color: white;
  border-bottom: 3px solid var(--color-brand-red-fade);
}
.related-content__link:hover {
  border-color: var(--color-grey-dark);
  text-decoration: underline;
}
.related-content__link:hover svg {
  transform: var(--arrow-hover);
}
.related-content__link svg {
  width: 3rem;
  height: 3rem;
  fill: currentColor;
}

.text-hero,
.post-hero {
  position: relative;
  padding: var(--padding-xl) 0;
  overflow: hidden;
}
.text-hero:before,
.post-hero:before {
  content: "";
  position: absolute;
  top: -10rem;
  right: -10rem;
  z-index: 1;
  aspect-ratio: 1/1;
  width: 40rem;
  opacity: 0.5;
}
@media (min-width: 1280px) {
  .text-hero:before,
.post-hero:before {
    top: -20rem;
    right: -20rem;
    width: 100rem;
  }
}
.text-hero .inner,
.post-hero .inner {
  position: relative;
  z-index: 2;
}

.text-hero--grey-light,
.post-hero {
  background: linear-gradient(0, rgb(247, 247, 247) 0%, rgb(240, 240, 240) 100%);
}
.text-hero--grey-light:before,
.post-hero:before {
  background: url("img/rradar-5.svg");
  background-size: 100% auto;
  background-position: center center;
}

.text-hero--red-mid {
  background-color: var(--color-brand-red-mid-fade);
}
.text-hero--red-mid:before {
  background: url("img/rradar-5.svg"), radial-gradient(circle, rgba(158, 23, 51, 0.1) 0%, rgba(158, 23, 51, 0) 50%);
  background-size: 100% auto;
  background-position: center center;
}

.text-hero--orange {
  background-color: var(--color-brand-orange-fade);
}
.text-hero--orange:before {
  background: url("img/rradar-5.svg"), radial-gradient(circle, rgba(254, 120, 34, 0.1) 0%, rgba(254, 120, 34, 0) 50%);
  background-size: 100% auto;
  background-position: center center;
}

.text-hero--purple {
  background-color: var(--color-brand-purple-fade);
}
.text-hero--purple:before {
  background: url("img/rradar-5.svg"), radial-gradient(circle, rgba(76, 29, 94, 0.1) 0%, rgba(76, 29, 94, 0) 50%);
  background-size: 100% auto;
  background-position: center center;
}

.text-hero--red {
  background-color: var(--color-brand-red-fade);
}
.text-hero--red:before {
  background: url("img/rradar-5.svg"), radial-gradient(circle, rgba(201, 0, 0, 0.1) 0%, rgba(201, 0, 0, 0) 50%);
  background-size: 100% auto;
  background-position: center center;
}

.text-hero--alert {
  background-color: var(--color-brand-red-mid);
}
.text-hero--alert:before {
  background: url("img/rradar-1.svg"), radial-gradient(circle, var(--color-brand-red) 0%, var(--color-brand-red-mid) 75%);
  background-size: 100% auto;
  background-position: center center;
}

.text-hero--with-topics,
.text-hero--login {
  padding: var(--padding-xl) 0 0;
}
@media (min-width: 768px) {
  .text-hero--with-topics:after,
.text-hero--login:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4rem;
    background-color: white;
  }
}

.text-hero--contact {
  color: white;
}
.text-hero--contact .title, .text-hero--contact .search-form__fields input[type=submit], .search-form__fields .text-hero--contact input[type=submit], .text-hero--contact .search-form__fields input[type=text], .search-form__fields .text-hero--contact input[type=text] {
  color: white;
}
.text-hero--contact a {
  color: white;
}

.text-hero-content {
  text-align: center;
}
.text-hero-content a.title {
  display: block;
}
.text-hero-content a.title:hover {
  color: var(--color-brand-red);
}

.text-hero__cols {
  display: grid;
  gap: var(--padding-md);
}
@media (min-width: 768px) {
  .text-hero__cols {
    grid-template-columns: 1fr 1fr;
  }
}

.text-hero__cols + .text-hero__cols {
  margin: var(--padding-md) 0 0;
}

.text-hero__col p {
  line-height: var(--line-height-md);
}

.post-hero {
  z-index: 1;
}

.post-hero-content {
  display: grid;
  gap: var(--padding-sm);
}

.post-hero__meta {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  color: var(--color-brand-red);
}

.accordion-block + .accordion-block {
  border-top: 1px solid var(--color-grey);
}

.accordion-block__toggle {
  display: flex;
  gap: var(--padding-lg);
  justify-content: space-between;
  width: 100%;
  padding: var(--padding-md) 0;
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-mid);
  background: none;
}
.accordion-block__toggle.is-active {
  color: var(--color-brand-purple);
}
.accordion-block__toggle.is-active svg {
  transform: rotate(-90deg);
}
.accordion-block__toggle:hover {
  color: var(--color-grey-dark);
}
.accordion-block__toggle svg {
  width: 2rem;
  fill: currentColor;
  transform: rotate(90deg);
}

.accordion-block__answer {
  display: none;
}
.accordion-block__answer.is-open {
  display: block;
  padding: 0 0 var(--padding-md);
}

.inner--lg .faqs {
  max-width: var(--mq-sm);
}

.vacancies {
  height: 50rem;
  border: none;
}

.content-cols--sidebar,
.content-cols--bio {
  display: grid;
  grid-gap: var(--padding-xl);
}
@media (min-width: 768px) {
  .content-cols--sidebar,
.content-cols--bio {
    grid-template-columns: 2fr 1fr;
  }
}
.content-cols--no-sidebar .content-col {
  max-width: calc(768px - var(--padding-md) * 2);
  margin: 0 auto;
}

.sidebar-col__img,
.sidebar-col__img-bio {
  display: block;
  width: 100%;
  height: auto;
}

.sidebar-col__img-bio {
  border-radius: 100%;
}

.post-content {
  position: relative;
  z-index: 2;
}
@media (min-width: 1280px) {
  .post-content .sidebar-col {
    transform: translateY(-12rem);
  }
}

.contact-info {
  font-family: var(--heading-font);
  line-height: var(--line-height-lg);
  letter-spacing: var(--kerning-lg);
}

.emergency-contact-cta {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}
.emergency-contact-cta svg {
  width: 2rem;
  height: 2rem;
  fill: white;
}

.emergency-contact-cta--red {
  background-color: var(--color-brand-red);
}

.emergency-contact-cta--grey-dark {
  background-color: var(--color-grey-dark);
}

.login-quicklinks {
  display: grid;
  gap: var(--padding-md);
}
@media (min-width: 768px) {
  .login-quicklinks {
    grid-template-columns: 1fr 1fr;
  }
}

.login-quicklink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--padding-xs);
  padding: var(--padding-sm) var(--padding-sm) var(--padding-md);
  background-color: var(--color-grey-light);
  border-bottom: 3px solid var(--color-grey);
}

.product-hero-outer {
  position: relative;
  background: linear-gradient(0, rgb(247, 247, 247) 0%, rgb(240, 240, 240) 100%);
}
.product-hero-outer .hero {
  padding: var(--padding-lg) 0;
}
@media (min-width: 1280px) {
  .product-hero-outer .hero {
    padding: 0;
  }
}

.product-hero-logo {
  display: block;
  width: auto;
  max-height: 5rem;
  mix-blend-mode: multiply;
}

.product-hero-imgs {
  display: none;
  position: relative;
  width: 60%;
  margin: var(--padding-xs) 0 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .product-hero-imgs {
    order: 2;
    display: block;
  }
}

.product-hero-imgs__img {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: auto;
}

.search-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: var(--padding-md);
  background-color: var(--color-brand-red-mid);
}
.search-modal .inner {
  width: 100%;
}
.search-modal.is-open {
  display: grid;
}

.search-modal__close {
  position: absolute;
  top: var(--padding-md);
  right: var(--padding-md);
  z-index: 1;
  width: 4rem;
  height: 4rem;
  background: none;
}
.search-modal__close svg {
  fill: white;
}

.search-form__fields {
  position: relative;
}
.search-form__fields input[type=text] {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 8rem;
  padding: 0 16rem 0 0;
  background: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 3px solid rgba(255, 255, 255, 0.25);
  color: white;
}
.search-form__fields input[type=text]::placeholder {
  color: rgba(255, 255, 255, 0.25);
}
.search-form__fields input[type=text]:focus {
  outline: none;
  border-color: white;
}
.search-form__fields input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 15rem;
  height: 8rem;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
.search-form__fields input[type=submit]:hover {
  opacity: 0.8;
}

.search-results {
  display: grid;
  gap: var(--padding-sm);
}

.search-result + .search-result {
  padding: var(--padding-sm) 0 0;
  border-top: 1px solid var(--color-grey-mid);
}

.client-list-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
}

.client-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--padding-md);
}
.client-list li {
  display: grid;
  place-items: center;
}

.client-list__logo {
  display: block;
  max-width: 10rem;
  max-height: 5rem;
  object-fit: contain;
  object-position: center;
}
@media (min-width: 1280px) {
  .client-list__logo {
    max-width: 15rem;
    max-height: 7.5rem;
  }
}

.img-quicklinks {
  display: grid;
  grid-gap: var(--padding-xs);
}
@media (min-width: 640px) {
  .img-quicklinks {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .img-quicklinks {
    grid-template-columns: repeat(4, 1fr);
  }
}

.img-quicklink {
  display: grid;
  gap: var(--padding-xs);
  align-content: start;
  position: relative;
  padding: var(--padding-md);
  background-color: white;
}
@media (min-width: 768px) {
  .img-quicklink {
    padding: var(--padding-sm) var(--padding-sm) 8rem;
  }
}
@media (min-width: 768px) {
  .img-quicklink .arrow-icon-btn {
    position: absolute;
    bottom: var(--padding-sm);
    left: var(--padding-sm);
  }
}

@media (min-width: 1280px) {
  .img-quicklink .title, .img-quicklink .search-form__fields input[type=text], .search-form__fields .img-quicklink input[type=text], .img-quicklink .search-form__fields input[type=submit], .search-form__fields .img-quicklink input[type=submit] {
    min-height: 7.5rem;
  }
}

.img-quicklink__img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.text-img {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .text-img {
    grid-template-columns: 1fr 2fr;
  }
}
@media (min-width: 1280px) {
  .text-img {
    grid-template-columns: 1fr calc(var(--mq-sm) - var(--padding-lg));
    gap: var(--padding-xl);
  }
}

.text-img__img {
  display: block;
  width: 50%;
  height: auto;
}
@media (min-width: 768px) {
  .text-img__img {
    width: 100%;
  }
}

.text-img__text {
  display: grid;
  justify-items: start;
  align-content: center;
  gap: var(--padding-md);
}

@media (min-width: 768px) {
  .text-img-outer--alt .text-img {
    grid-template-columns: 2fr 1fr;
  }
}
@media (min-width: 1280px) {
  .text-img-outer--alt .text-img {
    grid-template-columns: calc(var(--mq-sm) - var(--padding-lg)) 1fr;
  }
}
@media (min-width: 768px) {
  .text-img-outer--alt .text-img__img {
    order: 2;
  }
}
@media (min-width: 768px) {
  .text-img-outer--alt .text-img__text {
    order: 1;
  }
}

.bg-grey-light .text-img__img {
  mix-blend-mode: multiply;
}

.text-img__text--align-start {
  align-content: start;
}

.location {
  display: grid;
  justify-items: start;
  gap: var(--padding-sm);
  margin: 0 0 var(--padding-md);
}

.address li {
  line-height: var(--line-height-md);
}

.social-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
}

.social-list__link {
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  border: 2px solid var(--color-grey-dark);
  background: none;
}
.social-list__link svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: var(--color-grey-dark);
}

.timeline-outer {
  position: relative;
  padding: var(--padding-lg) 0;
}
.timeline-outer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 1rem;
  z-index: 1;
  display: block;
  width: 0.5rem;
  height: 100%;
  background-color: var(--color-grey);
}
@media (min-width: 768px) {
  .timeline-outer:before {
    left: calc(50% - 0.25rem);
  }
}

.bg-grey-light .timeline-outer:before {
  background-color: white;
}

.timeline {
  display: grid;
  gap: var(--padding-lg);
  position: relative;
  z-index: 2;
}

.timeline__item {
  padding: 0 0 0 5rem;
}
@media (min-width: 768px) {
  .timeline__item {
    display: grid;
    gap: var(--padding-sm);
    align-items: center;
    width: 50%;
  }
}

.timeline__img-outer {
  position: relative;
  margin: 0 0 var(--padding-md);
}
@media (min-width: 768px) {
  .timeline__img-outer {
    margin: 0;
  }
}
.timeline__img-outer:before {
  content: "";
  position: absolute;
  top: calc(5rem - 1px);
  left: -4rem;
  z-index: 1;
  width: 5rem;
  height: 2px;
  background-color: var(--color-grey-mid);
}
.timeline__img-outer:after {
  content: "";
  position: absolute;
  top: 4rem;
  left: -4.75rem;
  z-index: 2;
  width: 2rem;
  height: 2rem;
  background-color: var(--color-grey-mid);
  border-radius: 100%;
}

@media (min-width: 768px) {
  .timeline__item:nth-child(odd) {
    display: grid;
    grid-template-columns: 1fr 10rem;
    padding: 0 5rem 0 0;
    text-align: right;
  }
  .timeline__item:nth-child(odd) .timeline__img-outer {
    order: 2;
  }
  .timeline__item:nth-child(odd) .timeline__img-outer:before {
    left: auto;
    right: -5rem;
  }
  .timeline__item:nth-child(odd) .timeline__img-outer:after {
    left: auto;
    right: -6rem;
  }
  .timeline__item:nth-child(odd) .timeline__text {
    order: 1;
  }
}

@media (min-width: 768px) {
  .timeline__item:nth-child(even) {
    display: grid;
    grid-template-columns: 10rem 1fr;
    justify-self: end;
  }
  .timeline__item:nth-child(even) .timeline__img-outer:before {
    left: -5rem;
  }
  .timeline__item:nth-child(even) .timeline__img-outer:after {
    left: -6rem;
  }
}

.timeline__img {
  position: relative;
  z-index: 2;
  display: block;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
}

.timeline__text {
  padding: 0 0 0 0.5rem;
}
@media (min-width: 768px) {
  .timeline__text {
    padding: 0;
  }
}

.download-form {
  margin: var(--padding-md) 0 0;
  padding: var(--padding-md);
  background-color: white;
  box-shadow: var(--feed-item-shadow);
}