.content-cols--sidebar,
.content-cols--bio {
  display: grid;
  grid-gap: var(--padding-xl);
  
    @include mq(screen-sm) {
      grid-template-columns: 2fr 1fr;
    }

    @include mq(screen-lg) {
      
    }

}

.content-cols--no-sidebar {
  
  .content-col {
    max-width: calc($screen-sm - calc(var(--padding-md)*2));
    margin: 0 auto;
  }

}

.sidebar-col__img,
.sidebar-col__img-bio {
  display: block;
  width: 100%;
  height: auto;
}

.sidebar-col__img-bio {
  border-radius: 100%;
}

.post-content {
  position: relative;
  z-index: 2;

    .sidebar-col {
      
      @include mq(screen-lg) {
        transform: translateY(-12rem);
      }

    }
    
}


