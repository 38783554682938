.content-carousel-outer {
  position: relative;
  overflow: hidden;

  background-color: var(--color-brand-red-mid);

    @include mq(screen-sm) {
      background: none;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 50%;
      height: 100%;

      background-color: var(--color-brand-red-mid);
    }

    .inner {
      position: relative;
      z-index: 2;

      background-color: var(--color-brand-red-mid);
    }

}

.content-carousel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-xl);
  margin: 0 auto;

    &:after {
      content: "";

      position: absolute;
      //top: 50%;
      left: -75%;
      z-index: 1;

      width: 100%;
      aspect-ratio: 1/1;

      background-image: url('img/rradar-4.svg');
      background-position: center center;
      background-size: 100% auto;

      opacity: 0.5;
    }

}

.content-carousel-text {
  padding: var(--padding-xl) 0 var(--padding-lg);

  border-bottom: 1px solid rgba(white, 0.25);

  position: relative;
  z-index: 2;

    p {
      color: white;

      @extend .supporting-text;
    }

    p + p {
      margin: var(--padding-xs) 0 0;
    }

    p a {
      color: white;
    }

}

.content-carousel--no-text {
  padding: calc(var(--padding-xl)*1.5) 0;
}

.content-carousel__slides {
  position: relative;
  z-index: 2;
}

.content-carousel__slide {
  position: relative;
  padding: var(--padding-lg) var(--padding-md) var(--padding-xl);
}

.tns-outer {
  width: 65%;
}

.tns-item {
  opacity: 0;
  transition: 1s opacity ease-in;
}

.tns-item.tns-slide-active {
  opacity: 1;
}

.content-carousel__control {
  position: relative;
  z-index: 2;
  display: none;
  //display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  background: none;

    @include mq(screen-xs) {
      display: grid;
    }

    svg {
      width: 4rem;
      height: 4rem;
      fill: white;
    }

    &:disabled {
      opacity: 0.5;
    }

}

.content-carousel__control--prev {
  
  svg {
    transform: rotate(-180deg);
  }

}

.content-carousel__slide--with-icon {
  position: relative;
  padding: var(--padding-lg) 0 var(--padding-lg) calc(5rem + var(--padding-md));

    @include mq(screen-xs) {
      padding: var(--padding-lg) calc(5rem + var(--padding-md));
    }
}

.content-carousel__slide__icon {
  position: absolute;
  top: var(--padding-lg);
  left: 0;
  z-index: 1;

  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;

  background-color: white;
  border-radius: 100%;

    img {
      display: block;
      width: 4rem;
      height: auto;
    }

}

.content-carousel__slide__header {
  width: 75%;
}

