.text-img {
  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      grid-template-columns: 1fr 2fr;
    }

    @include mq(screen-lg) {
      grid-template-columns: 1fr calc(var(--mq-sm) - var(--padding-lg));
      gap: var(--padding-xl);
    }

}

.text-img__img {
  display: block;
  width: 50%;
  height: auto;

    @include mq(screen-sm) {
      width: 100%;
    }

}

.text-img__text {
  display: grid;
  justify-items: start;
  align-content: center;
  gap: var(--padding-md);
}

// Orientation

.text-img-outer--alt {

  .text-img {

    @include mq(screen-sm) {
      grid-template-columns: 2fr 1fr;
    }
    
    @include mq(screen-lg) {
      grid-template-columns: calc(var(--mq-sm) - var(--padding-lg)) 1fr;
    }

  }
  
  .text-img__img {
    
    @include mq(screen-sm) {
      order: 2;
    }

  }

  .text-img__text {
    
    @include mq(screen-sm) {
      order: 1;
    }

  }

}

.bg-grey-light .text-img__img {
  mix-blend-mode: multiply;
}

// States
  
.text-img__text--align-start {
  align-content: start;
}