.featured-content {
  position: relative;
}

.featured-content-row {
  position: relative;
  

    &:nth-child(odd) {
      z-index: 2;
      padding: var(--padding-xl) 0 0;
      background-color: var(--color-brand-red-mid);

        .featured-content-text {
          
          @include mq(screen-sm) {
            padding: 0 0 var(--padding-xl) 0;
          }

        }

        .btn {
          @extend .btn--white;
        }

        &:after {

          @include mq(screen-xl) {
            content: "";

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: var(--padding-xl);
            //width: var(--padding-lg);
            height: 100%;

            background-color: white;
          }

          @include mq(screen-xl) {
            //width: var(--padding-lg);
          }

        }

    }

    &:nth-child(even) {
      z-index: 1;
      padding: var(--padding-xl) 0;

        .featured-content-text {
          
          @include mq(screen-sm) {
            order: 2;
            padding: 0 var(--padding-lg) 0 0;
          }

        }

        .featured-content-media {
          
          @include mq(screen-sm) {
            order: 1;
          }

        }

        .btn {
          @extend .btn--purple-fade;
        }

    }

}

.featured-content-cols {
  display: grid;
  gap: var(--padding-xl);

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

}

.featured-content-media {
  
  .content-feed__item {
    width: 100%;
  }

}

// Style variants

.featured-content-row:nth-child(odd) .std-content {
	// Main headings

	h2, h3, h4, h5 {
		color: white;
	}

	// Paragraphs

	p,ul,ol {
    color: white;

		a {
			color: white;
		}

		a:hover {
      color: white;
			opacity: 0.8;
		}

		& > strong {
			color: white;
		}
					
	}

	p {
		line-height: var(--line-height-md);
	}

	ul, ol {

		li::marker {
			color: white;
		}

	}

	a {
		color: white;
		text-decoration: underline;

			&:hover {
        color: white;
				opacity: 0.8;
			}

	}

}

// Variant styling

.featured-content-row--post .featured-content-media {

  @include mq(screen-sm) {
    display: flex;
    align-items: flex-end;
    transform: translateY(10rem);
  }

}

.featured-content-row--post + .featured-content-row .featured-content-text {
  
  @include mq(screen-sm) {
    padding: 10rem 0 0;
  }

}