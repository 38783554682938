.two-col-text-outer {
  
  .title {
    //max-width: 60%;
    width: max(60%, 30rem);
  }

}

.two-col-text {
  
  @include mq(screen-sm) {
    columns: 2;
    column-gap: var(--padding-lg);
    break-inside: avoid;
  }

}