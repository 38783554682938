.flex-img {
  @include flex-img;
}

.align-center {
  text-align: center;
}

.screen-reader-only {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.bg-grey-light {
  background-color: var(--color-grey-light);
}