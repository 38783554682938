.content-feed-outer {
  position: relative;
  padding: var(--padding-xl) 0 0;
  //overflow: scroll;

    .inner,
    .overflow {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 75%;

      // background-color: var(--color-grey-light);

        @include mq(screen-xl) {
          left: var(--padding-xl);

          width: calc(100% - var(--padding-xl));
        }

    }

}

.content-feed-outer--grey-light:after {
  background-color: var(--color-grey-light);
}

.content-feed-outer--red-mid:after {
  background-color: var(--color-brand-red-mid);
}

.content-feed-outer--red-mid.is-even:after {
  background-color: var(--color-brand-red-dark);
}

.content-feed-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}

.content-feed-header__text {
  display: grid;
  gap: var(--padding-md);
  width: max(60%, 30rem);
}

.content-feed-title {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
}

.content-feed-title__icon {
  display: block;
  width: 2rem;
  height: 2rem;

    @include mq(screen-sm) {
      width: 4rem;
      height: 4rem;
    }

}

.topics-cta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-sm);
}

.content-feed__item {
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-sm) 0;

  background-color: white;
  box-shadow: var(--feed-item-shadow);

    p {
      @extend .feed-text;
    }

    p + p {
      margin: 0.5rem 0 0;
    }

}

.content-feed__item__img-outer {
  aspect-ratio: 3/2;
}

.content-feed__item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content-feed--awards .content-feed__item__img-outer {
  display: grid;
  place-items: center;
  height: 20rem;

  aspect-ratio: unset;
}

.content-feed--awards .content-feed__item__img {
  max-width: 100%;
  max-height: 20rem;
  width: auto;
  height: auto;
  object-fit: contain;
}

.content-feed--fixed,
.content-feed--locations {
  display: grid;
  gap: var(--padding-xs);
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 30rem), 1fr));

}

.content-feed--slider {
  display: flex;
  gap: var(--padding-xs);
  padding: 0.5rem;
  overflow: scroll;
  
  -ms-overflow-style: none;
  scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }


    .content-feed__item {
      flex-shrink: 0;
      width: 30rem;

        @include mq(screen-sm) {
          width: 40rem;
        }

    }

}

.content-feed__item__link {
  flex: 1;
  position: relative;
  display: block;
  padding: 0 0 var(--padding-xl);

  text-decoration: none;

    &:hover {
      
      .title--md {
        text-decoration: underline;
      }

      svg {
        transform: var(--arrow-hover);
      }

    }
  
    .title {
      color: var(--color-grey-dark);
    }

    .supporting-text {
      color: var(--color-grey-mid);
    }

    svg {
      position: absolute;
      bottom: var(--padding-sm);
      left: 0;
      z-index: 1;

      display: block;
      width: min(10%, 3rem);

      fill: var(--color-brand-red);
    }


}

.content-feed__item__label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  font-family: var(--heading-font);
  letter-spacing: var(--kerning-lg);
  font-size: 1.6rem;
  color: var(--color-brand-red);
  line-height: 1;

    &:before {
      content: "";

      display: block;
      width: 1rem;
      height: 1rem;

      background-color: var(--color-brand-red);
      border-radius: 100%;
    }

}

.content-feed__item__label--event {
  color: var(--color-grey-dark);

    &:before {
      background-color: var(--color-grey-dark);
    }

}

.content-type-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 var(--padding-sm);
}

.content-type-title__icon {
  display: block;
  width: 2rem;
  height: 2rem;
}

// Variant

.content-feed__img--no-img .title--md {
  padding: 0 0 var(--padding-xs);
  border-bottom: 1px solid var(--color-grey-mid);
}

// Controls 

.content-feed-controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.content-feed-control {
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;

  background-color: var(--color-brand-purple-fade);
  border-radius: var(--btn-radius);

  cursor: pointer;

    &:hover {
      background-color: var(--color-brand-purple);

        svg {
          fill: white;
        }

    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: var(--color-brand-purple);
    }

}

.content-feed-control--left {
  transform: rotate(-180deg);
}

.content-feed + .content-feed-controls {
  margin: var(--padding-md) 0;
} 

// Pagination

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xs);
}

.pagination-pg {
  display: block;
  width: 2rem;
  height: 2rem;

  background: none;

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  letter-spacing: var(--kerning-lg);
  color: var(--color-grey-mid);
  text-decoration: underline;
  text-align: center;

    &:hover {
      color: var(--color-brand-red);
    }

    &.mixitup-control-active {
      color: var(--color-brand-red-mid);
    }
}

.pagination-control {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;

  background: none;

    &:hover svg {
      fill: var(--color-brand-red);      
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: var(--color-grey-mid);
    }

}

.pagination-control--prev svg {
  transform: rotate(180deg);
}