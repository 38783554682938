.contact-form {
  display: grid;
  gap: var(--padding-lg);
}

.contact-form--with-img {
  
  @include mq(screen-sm) {
    grid-template-columns: 1fr 1fr;
  }

}

.contact-form__img-outer {
  display: block;
  margin: var(--padding-lg) 0 0;

  position: relative;

    &:after {
      content: "";

      position: absolute;
      z-index: 2;
      bottom: 45%;
      right: 45%;

      aspect-ratio: 1/1;
      width: 20%;

      border-radius: 100%;
      background: url('img/rradar-1.svg');
      background-size: 100% auto;
      mix-blend-mode: multiply;

        @include mq(screen-sm) {
          bottom: 0%;
          right: 30%;
          width: 40%;
        }
    }

    @include mq(screen-sm) {
      padding: 0 0 20%;
    }

}

.contact-form__logo {
  margin: 0 0 var(--padding-md);
  
    svg,
    img {
      display: block;
      width: auto;
      height: 5rem;
    }

}

.contact-form__img {
  display: block;
  width: 40%; // 75%
  height: auto;

  aspect-ratio: 1/1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

