.related-content-outer {
  position: relative;
  padding: var(--padding-xl) 0;
  background-color: var(--color-brand-red-mid);

  overflow: hidden;

    &:before {
      content: "";

      position: absolute;
      left: 40%;
      top: 50%;
      transform: translateY(-50%);

      width: 100%;
      aspect-ratio: 1/1;

      background-color: var(--color-brand-red-dark);
      opacity: 0.2;

      border-radius: 100%;
    }

    &:after {
      content: "";

      position: absolute;
      left: 60%;
      top: 50%;
      transform: translateY(-50%);

      width: 75%;
      aspect-ratio: 1/1;

      background-color: var(--color-brand-red-dark);
      opacity: 0.2;

      border-radius: 100%;
    }

    .inner {
      position: relative;
      z-index: 3;
    }

}

.related-content {
  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
      gap: var(--padding-md) var(--padding-lg);
    }
}

.related-content__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-xl);
  padding: var(--padding-md);

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-md);
  line-height: var(--line-height-md);
  color: var(--color-brand-red-mid);
  text-decoration: none;
  letter-spacing: var(--kerning-lg);

  background-color: white;
  border-bottom: 3px solid var(--color-brand-red-fade);

    &:hover {
      border-color: var(--color-grey-dark);
      text-decoration: underline;

        svg {
          transform: var(--arrow-hover);
        }

    }
    
    svg {
      width: 3rem;
      height: 3rem;

      fill: currentColor;
    }

}