.login-form {
  padding: var(--padding-md);

  background-color: white;
  box-shadow: var(--feed-item-shadow);
}

.tml-field-wrap + .tml-field-wrap {
  margin: var(--padding-md) 0 0;
}

.tml-label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;

  font-family: var(--heading-font);
  color: var(--color-grey-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--kerning-lg);
}

.tml-field-wrap {
  input[type="text"],
  input[type="number"],
  input[type="url"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  select {
    display: block;
    width: 100%;
    height: 6rem;
    padding: 0 1rem;

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid var(--color-grey-mid);
    background-color: var(--color-grey-light);

    font-size: var(--fs-fluid-sm);

      &:focus {
        outline: none;
        border-color: var(--color-brand-red);
      }

  }

}


.tml-rememberme-wrap {
  margin: var(--padding-sm) 0 0;

    label {
      display: inline;
      margin: 0;
    }

}

.tml-button {
  @extend .btn;
  @extend .btn--purple-fade;  
}

.tml-login .tml-alerts,
.tml-lostpassword .tml-alerts {
  //margin: 0 0 var(--padding-sm);
}

.tml-errors {
  padding: var(--padding-xs);
  margin: 0 0 var(--padding-xs);

  font-size: var(--fs-fluid-xs);
  color: white;
  list-style-type: none;

  background-color: var(--color-error);
}

.tml-error {
  color: white;

    strong {
      font-weight: inherit;
      text-decoration: underline;
    }
    
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
  margin: var(--padding-md) 0 0;

  list-style-type: none;

    a {
      font-size: var(--fs-fluid-xs);
      color: var(--color-grey-dark);
    }

    a:hover {
      color: black;
    }

}

.no-flexbox-gap {

  .tml-links li + .tml-links li {
    margin: var(--padding-xs) 0 0;
  }

}

.tml-messages {
  margin: 0 0 var(--padding-md);
  padding: var(--padding-xs);
  list-style-type: none;

  background-color: var(--color-success);
}

.tml-message {
  color: white;

    a {
      color: inherit;
    }

}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}
