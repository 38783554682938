.client-list-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
}

.client-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--padding-md);

    li {
      display: grid;
      place-items: center;
    }

}

.client-list__logo {
  display: block;
  max-width: 10rem;
  max-height: 5rem;
  object-fit: contain;
  object-position: center;

    @include mq(screen-lg) {
      max-width: 15rem;
      max-height: 7.5rem;
    }

}