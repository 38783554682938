.pg-footer-outer {
  padding: var(--padding-xl) 0;

  //background-color: var(--color-grey-light);
  background: linear-gradient(0, rgba(247,247,247,1) 0%, rgba(240,240,240,1) 100%);
}

.pg-footer {
  display: grid;
  gap: var(--padding-xl);

  transform: translateY(100px);
  transition: all 0.75s ease-in-out;
  opacity: 0;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

}

.pg-footer-outer.in-viewport .pg-footer {
  transform: translateY(0);
  opacity: 1;
}

.pg-footer .contact-info a {
  color: var(--color-grey-dark);

    &:hover {
      color: var(--color-brand-red);
      text-decoration: underline;
    }

}

.pg-footer-contacts {
  display: flex;
  flex-direction: column;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      flex-direction: row;
    }
    
}

.pg-footer-contacts__logo {
  display: block;
  width: 5rem;
  height: 5rem;

    @include mq(screen-lg) {
      width: 8rem;
      height: 8rem;
    }

}

.pg-footer-nav-outer {
  display: grid;
  align-content: start;
  gap: var(--padding-lg);
}

.pg-footer-navs {

}

.pg-footer-nav {
  display: grid;
  gap: var(--padding-lg);
  align-items: start;

    @include mq(screen-lg) {
      grid-template-columns: 1fr 1fr;
    }

}

.pg-footer-nav__list {
  display: grid;
  gap: 1rem;

    a {
      font-family: var(--heading-font);
      letter-spacing: var(--kerning-lg);
      color: var(--color-grey-dark);
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

}

// CREDS

.creds {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--padding-md);

    @include mq(screen-sm) {
      justify-content: center;
      gap: var(--padding-lg);
    }

}

.cred {
  display: block;
  max-width: 8rem;
  max-height: 6rem;

    @include mq(screen-sm) {
      max-width: 16rem;
      max-height: 10rem;
    }

}

.cred-link {
  display: block;
}

.copyright {
  
  @include mq(screen-sm) {
    text-align: center;
  }

}