.breadcrumbs {
  padding: var(--padding-sm) 0;

  background-color: var(--color-grey-light);
}

.breadcrumb__items {
  font-family: var(--heading-font);
  font-size: 1.4rem;
  letter-spacing: var(--kerning-lg);
  color: var(--color-brand-red);

    a {
      color: var(--color-brand-red);
    }
}