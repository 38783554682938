.search-modal {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  align-items: center;
  width: 100%;
  height: 100vh;
  padding: var(--padding-md);

  background-color: var(--color-brand-red-mid);

    .inner {
      width: 100%;
    }

    &.is-open {
      display: grid;
    }

}

.search-modal__close {
  position: absolute;
  top: var(--padding-md);
  right: var(--padding-md);
  z-index: 1;

  width: 4rem;
  height: 4rem;

  background: none;

    svg {
      fill: white;
    }

}

.search-form__fields {
  position: relative;

    input[type="text"] {
      position: relative;
      z-index: 1;

      width: 100%;
      height: 8rem;
      padding: 0 16rem 0 0;

      background: none;

      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 3px solid rgba(white, 0.25);

      color: white;

      @extend .title;
      @extend .title--xl;
      @extend .text-white;

        &::placeholder {
          color: rgba(white, 0.25);
        }

        &:focus {
          outline: none;
          border-color: white;
        }

    }

    input[type="submit"] {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      width: 15rem;
      height: 8rem;

      background: none;
      border: none;

      text-decoration: underline;
      cursor: pointer;

      @extend .title;
      @extend .title--lg;
      @extend .text-white;

        &:hover {
          opacity: 0.8;
        }

    }

}

.search-results {
  display: grid;
  gap: var(--padding-sm);
}


.search-result + .search-result {
  padding: var(--padding-sm) 0 0;
  border-top: 1px solid var(--color-grey-mid);
}

