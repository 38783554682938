.testimonial {
  position: relative;

    @include mq(screen-sm) {
      display: flex;

      background-color: var(--color-grey-light);
    }
}

.testimonial-quote-outer {
  
  @include mq(screen-sm) {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }

}

.testimonial-quote-inner {
  padding: var(--padding-md);

    @include mq(screen-sm) {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: calc(var(--mq-lg) / 2);
      padding: var(--padding-md) 0 var(--padding-md) var(--padding-md);
    }

}

.testimonial-img-outer {
  display: none;

    @include mq(screen-sm) {
      display: block;
      width: 50%;
    }

}

.testimonial-img {

  @include mq(screen-sm) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.testimonial-quote {
  display: grid;
  grid-gap: var(--padding-md);
  padding: var(--padding-md);

  background-color: white;
  box-shadow: var(--feed-item-shadow);

    @include mq(screen-sm) {
      position: relative;

        &:after {
          content: "";

          position: absolute;
          top: 0;
          left: 100%;
          z-index: 1;

          width: 4rem;
          height: 100%;

          background-color: white;
        }
    }

}

.testimonial-quote__icon {
  display: block;
  width: 6rem;
  height: 6rem;
  padding: 0.75rem;

  border-radius: 100%;
  background-color: var(--color-brand-red-fade);

    div {
      width: 4.5rem;
      height: 4.5rem;
      padding: 0.75rem;

      border-radius: 100%;
      background-color: var(--color-brand-red);
    }

    svg {
      width: 3rem;
      height: 3rem;

      fill: white;
    }

}

