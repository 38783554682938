// =================================================
// STANDARD CONTENT STYLES
// =================================================

.std-content {
	width: 100%;

	// & > *+* {
	// 	margin: var(--padding-sm) 0 0;
	// }

  & > *:not(h2, h3, h4, h5, h6)+* {
		margin: var(--padding-sm) 0 0;
	}
	
	// Main headings

	h2:not(:first-child), h3:not(:first-child), h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
		margin: var(--padding-md) 0 var(--padding-xs);
	}

  h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
		margin: 0 0 var(--padding-xs);
	}
	
	// Main headings

	h2, h3, h4, h5 {
		font-family: var(--heading-font);
		line-height: var(--line-height-md);
		color: var(--color-grey-dark);
		letter-spacing: var(--kerning-lg);

      &:after {

      }

	}

  h2 {
    font-size: var(--fs-fluid-lg);
  }

  h3 {
    font-size: var(--fs-fluid-md);
  }

  h4 {
    font-size: var(--fs-fluid-sm);
  }

  h5 {
    font-size: var(--fs-fluid-sm);
  }


	// Paragraphs

	p,ul,ol {

		a {
			color: var(--color-brand-red);
			text-decoration: underline;
		}

		a:hover {
			color: black;
		}

		& > strong {
			font-weight: var(--font-weight-bold);
			color: black;
		}

		& > em {
			font-style: italic;
		}
					
	}

	p {
		line-height: var(--line-height-md);
	}

	sup {
		font-size: 75%;
	}

	ul, ol {

		li::marker {
			color: var(--color-brand-red);
		}

		li > *:last-child {
			padding: 0 0 var(--padding-sm);
		}

	}

	ul {
		list-style-type: disc;
		padding-left: var(--padding-sm);
	}

	ol {
		list-style-type: decimal;
		padding-left: var(--padding-sm);
		counter-reset: item;
	}

	ul ul {
		margin: 1rem 0 0 3rem;
		list-style-type: circle;
	}

	li {		
		line-height: var(--line-height-lg);
	}

	li + li {
		margin-top: 1rem;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: var(--padding-md) auto;
	}

	a {
		color: inherit;
		text-decoration: underline;

			&:hover {
				color: black;
			}

	}

	blockquote {
	}

	address {
		font-style: normal;
	}


}

// WP overrides

.std-content {

	img.alignleft,
	img.alignright {
		width: 50%;
		margin: var(--padding-md) auto;

			@include mq(screen-xs) {
				width: 20%;
				clear: both;
				margin-top: 0.5rem;
				margin-bottom: var(--padding-sm);
			}

			@include mq(screen-lg) {
				width: 25%;
			}

	}

	img.alignleft {
		
		@include mq(screen-xs) {
			float: left;
			margin-right: var(--padding-md);
		}

	}

	img.alignright {
		
		@include mq(screen-xs) {
			float: right;
			margin-left: var(--padding-md);
		}

	}

	img.alignnone {
		clear: both;
	}

	.wp-caption {
		width: 100% !important;
		height: auto;
		margin: var(--padding-md) auto;


			img {
				margin: 0;
			}
			
	}

	p.wp-caption-text {
		margin: 0 auto;
		padding: 1rem;

		font-size: var(--fs-fluid-xs);
		line-height: var(--line-height-sm);
		text-align: center;
    color: var(--color-grey-dark);

		background-color: var(--color-grey-light);
	}

}

.std-content iframe,
.video-embed iframe {
  width: 100%;
  height: 100%;

  aspect-ratio: 16 / 9;
}

.std-content .video-embed {
	margin: var(--padding-lg) 0;

		&:last-child {
			margin: var(--padding-lg) 0 0;
		}
		
}

// Tables

.std-content table {
	table-layout:fixed;
	width: 100%;
	margin: var(--padding-lg) 0;

	border-collapse: collapse;
  border-spacing: 0px;

  vertical-align: top;

	background-color: white;

		&:first-child {
			margin: 0 0 var(--padding-lg);
		}

		th, td {
			vertical-align: top;
			padding: 1.5rem 1rem;
		}

		th {
			border-bottom: 3px solid var(--color-grey-light);
			font-size: 1.6rem;
			font-weight: var(--font-weight-bold);
		}

		tr:nth-child(even) td {
			background-color: var(--color-grey-light);
		}

		td {
			font-size: 1.4rem;
			line-height: var(--line-height-sm);
		}

		.tier {
			display: block;
			//padding: 0 0 1rem;
	
			font-size: var(--fs-fluid-md);
			font-weight: var(--font-weight-bold);
		}
	
		.price {
			font-weight: var(--font-weight-bold);
			font-size: var(--fs-fluid-lg);
		}
	
		.duration {
			font-weight: 300;
		}

		.italic {
			font-style: italic;
		}

}

.content-col.std-content table {
	@include mq(screen-sm) {
		width: 100%;
		transform: none;
	}
}

.tablepress-id-packages-1 {

	td:first-child {
		font-weight: var(--font-weight-bold);
	}

}
