.newsletter-form-outer {
  position: relative;
  padding: var(--padding-xl) 0;

  overflow: hidden;
  background-color: white;

    .inner {
      position: relative;
      z-index: 3;
    }

    &:after {

      @include mq(screen-md) {
        content: "";

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1;

        width: 60%;
        height: 100%;

        background: url('img/newsletter-signup-bg-new.jpg') no-repeat right center;
        background-size: auto 100%;

        opacity: 0.5;
      }

      @include mq(screen-md) {
        max-width: var(--mq-md);
        opacity: 1;
      }

      // @include mq(screen-lg) {
       
      //   right: 55%;
      //   background-size: cover;
      // }

      @include mq(screen-xxl) {

      }

    }

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      display: block;
      width: 100%;
      height: 10rem;

      background: linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(247,247,247,0) 100%);
      mix-blend-mode: multiply;
    }


}

.newsletter-form-inner {
  
  @include mq(screen-md) {
    display: flex;
    justify-content: flex-end;
  }

}

.newsletter-form {
  display: grid;
  gap: var(--padding-sm);

    @include mq(screen-md) {
      width: calc(50% - var(--padding-lg));
    }

}

.newsletter-form__row + .newsletter-form__row {
  margin: var(--padding-md) 0 0;
}

.newsletter-form__label {
  display: block;
  margin: 0 0 var(--padding-xs);

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
  color: var(--color-grey-dark);
  letter-spacing: var(--kerning-lg);
}

.newsletter-form__row input[type="email"] {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid var(--color-grey-mid);
  background-color: var(--color-grey-light);

  font-size: var(--fs-fluid-xs);

    &:focus {
      outline: none;
      border-color: var(--color-brand-red);
    }

}

.newsletter-form__row .wpcf7-checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs) var(--padding-md);

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-md);
  color: var(--color-grey-dark);

    .wpcf7-list-item label {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    input[type="checkbox"] {
      border: none;
      transform: scale(1.5);
    }

}

.newsletter-form__row input[type="submit"] {
  @extend .btn;
  @extend .btn--red-fade;
}