.text-hero,
.post-hero {
  position: relative;
  padding: var(--padding-xl) 0;
  overflow: hidden;

    &:before {
      content: "";

      position: absolute;
      top: -10rem;
      right: -10rem;
      z-index: 1;

      aspect-ratio: 1/1;
      width: 40rem;

      opacity: 0.5;

        @include mq(screen-lg) {
          top: -20rem;
          right: -20rem;

          width: 100rem;
        }

    }
    
    .inner {
      position: relative;
      z-index: 2;
    }

}

.text-hero--grey-light,
.post-hero {
  background: linear-gradient(0, rgba(247,247,247,1) 0%, rgba(240,240,240,1) 100%);

    &:before {
      background: url('img/rradar-5.svg');
      background-size: 100% auto;
      background-position: center center;
    }

}

.text-hero--red-mid {
  background-color: var(--color-brand-red-mid-fade);
  
    &:before {
      background: url('img/rradar-5.svg'), radial-gradient(circle, rgba(158, 23, 51, 0.1) 0%, rgba(158, 23, 51, 0) 50%);
      background-size: 100% auto;
      background-position: center center;
    }

}

.text-hero--orange {
  background-color: var(--color-brand-orange-fade);
  
    &:before {
      background: url('img/rradar-5.svg'), radial-gradient(circle, rgba(254, 120, 34, 0.1) 0%, rgba(254, 120, 34, 0) 50%);
      background-size: 100% auto;
      background-position: center center;
    }

}

.text-hero--purple {
  background-color: var(--color-brand-purple-fade);
  
    &:before {
      background: url('img/rradar-5.svg'), radial-gradient(circle, rgba(76, 29, 94, 0.1) 0%, rgba(76, 29, 94, 0) 50%);
      background-size: 100% auto;
      background-position: center center;
    }

}

.text-hero--red {
  background-color: var(--color-brand-red-fade);
  
    &:before {
      background: url('img/rradar-5.svg'), radial-gradient(circle, rgba(201, 0, 0, 0.1) 0%, rgba(201, 0, 0, 0) 50%);
      background-size: 100% auto;
      background-position: center center;
    }

}

.text-hero--alert {
  background-color: var(--color-brand-red-mid);
  
    &:before {
      background: url('img/rradar-1.svg'), radial-gradient(circle, var(--color-brand-red) 0%, var(--color-brand-red-mid) 75%);
      background-size: 100% auto;
      background-position: center center;
    }

}

.text-hero--with-topics,
.text-hero--login {
  padding: var(--padding-xl) 0 0;

    &:after {

      @include mq(screen-sm) {
        content: "";

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 4rem;

        background-color: white;
      }

    }
    
}

.text-hero--contact {
  color: white;

    .title {
      color: white;
    }

    a {
      color: white;
    }
}

.text-hero-content {
  text-align: center;

    a.title {
      display: block;
    }

    a.title:hover {
      color: var(--color-brand-red);
    }

}

.text-hero__cols {
  display: grid;
  gap: var(--padding-md);
    
    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

}

.text-hero__cols + .text-hero__cols {
  margin: var(--padding-md) 0 0;
}

.text-hero__col p {
  line-height: var(--line-height-md);
}

// Post variant

.post-hero {
  z-index: 1;
}

.post-hero-content {
  display: grid;
  gap: var(--padding-sm);
  //max-width: var(--mq-sm);
}

.post-hero__meta {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  color: var(--color-brand-red);
}