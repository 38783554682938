.social-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
}

.social-list__link {
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;

  border-radius: 100%;
  border: 2px solid var(--color-grey-dark);
  background: none;

    svg {
      width: 2.4rem;
      height: 2.4rem;

      fill: var(--color-grey-dark);
    }

}