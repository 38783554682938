.cta--lg {
  position: relative;
  padding: var(--padding-xl) 0;

  overflow: hidden;

    .inner {
      position: relative;
      z-index: 3;
    }

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;

      width: 70%;

      opacity: 0.5;

      fill: var(--color-brand-red);

        @include mq(screen-sm) {
          opacity: 1;
        }

    }

}

.cta--std {
  background-color: var(--color-brand-red-dark);

    svg {
      right: -20%;
    }

    &:after {
      content: "";

      position: absolute;
      top: 50%;
      right: -20%;
      transform: translateY(-50%);
      z-index: 1;

      aspect-ratio: 1/1;
      width: 70%;

      background: radial-gradient(circle, rgba(158,23,51,1) 0%, rgba(100,19,51,1) 50%);
      background-size: 100% auto;
      background-position: center center;

      opacity: 0.5;

        @include mq(screen-sm) {
          opacity: 1;
        }

    }

}

.cta--alt {
  background-color: var(--color-brand-red-mid);

    svg {
      left: -20%;
    }

    &:after {
      content: "";

      position: absolute;
      top: 50%;
      left: -20%;
      transform: translateY(-50%);
      z-index: 1;

      aspect-ratio: 1/1;
      width: 70%;

      background: radial-gradient(circle, rgba(201,0,0,1) 0%, rgba(158,23,51,1) 50%);
      background-size: 100% auto;
      background-position: center center;

      opacity: 0.5;

        @include mq(screen-sm) {
          opacity: 1;
        }

    }

    .cta {
      justify-content: flex-end;
    }

}

.cta {
  position: relative;
  display: flex;
}

.cta-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
}

.cta--lg {
  
  .cta-text {
    gap: var(--padding-md);

      @include mq(screen-sm) {
        width: calc(50% - var(--padding-lg));
      }
      
  }

}

.cta--sm {
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: var(--padding-lg) 2.5% var(--padding-lg) var(--padding-lg);

  text-decoration: none;
  color: white;

    &:hover {

      .title {
        text-decoration: underline;
      }

      .cta-arrow {
        transform: var(--arrow-hover);
      }

    }

    .cta-text {
      position: relative;
      z-index: 2;
      gap: var(--padding-sm);
      padding: 0 var(--padding-xl) 0 0;
    }

    > svg {
      position: absolute;
      top: 50%;
      right: -10%;
      transform: translateY(-50%);
      z-index: 1;

      width: 30%;

      fill: white;
      opacity: 0.2;
    }

}

.cta-arrow {
  width: 5%;
  height: auto;

  fill: white;
}

.cta--red {
  background-color: var(--color-brand-red);
}

.cta--red-mid {
  background-color: var(--color-brand-red-mid);
}

.cta--red-dark {
  background-color: var(--color-brand-red-dark);
}

.cta--purple {
  background-color: var(--color-brand-purple);
}

// Animating SVGs

.cta-outer.cta--lg.in-viewport {
  .ring-1 {
    // animation: rradar 0.25s ease-in-out 1;
    animation: rradar 0.35s ease-in-out 1, rradar 0.35s ease-in-out 1.5s 1;
  }
  .ring-2 {
    // animation: rradar 0.23s ease-in-out 0.25s 1;
    animation: rradar 0.33s ease-in-out 0.35s 1, rradar 0.33s ease-in-out 1.85s 1;
  }
  .ring-3 {
    // animation: rradar 0.21s ease-in-out 0.44s 1;
    animation: rradar 0.31s ease-in-out 0.54s 1, rradar 0.31s ease-in-out 2.04s 1;
  }
  .ring-4 {
    // animation: rradar 0.19s ease-in-out 0.63s 1;
    animation: rradar 0.29s ease-in-out 0.73s 1, rradar 0.29s ease-in-out 2.23s 1;
  }
  .ring-5 {
    // animation: rradar 0.17s ease-in-out 0.8s 1;
    animation: rradar 0.27s ease-in-out 0.9s 1, rradar 0.27s ease-in-out 2.4s 1;
  }
  .ring-6 {
    // animation: rradar 0.15s ease-in-out 0.95s 1;
    animation: rradar 0.25s ease-in-out 1.05s 1, rradar 0.25s ease-in-out 2.55s 1;
  }
  .ring-7 {
    // animation: rradar 0.13s ease-in-out 1.08s 1;
    animation: rradar 0.23s ease-in-out 1.18s 1, rradar 0.23s ease-in-out 2.68s 1;
  }
  .ring-8 {
    // animation: rradar 0.11s ease-in-out 1.19s 1;
    animation: rradar 0.21s ease-in-out 1.29s 1, rradar 0.21s ease-in-out 2.79s 1;
  }
  .ring-9 {
    // animation: rradar 0.09s ease-in-out 1.28s 1;
    animation: rradar 0.19s ease-in-out 1.38s 1, rradar 0.19s ease-in-out 2.88s 1;
  }
  .ring-10 {
    // animation: rradar 0.07s ease-in-out 1.35s 1;
    animation: rradar 0.17s ease-in-out 1.45s 1, rradar 0.17s ease-in-out 2.95s 1;
  }
}

.cta-outer.in-viewport .cta--sm {
  .ring-1 {
    animation: rradar 0.2s ease-in-out 1, rradar 0.2s ease-in-out 0.8s 1;
  }
  .ring-2 {
    animation: rradar 0.2s ease-in-out 0.2s 1, rradar 0.2s ease-in-out 1s 1;
  }
  .ring-3 {
    animation: rradar 0.2s ease-in-out 0.4s 1, rradar 0.2s ease-in-out 1.2s 1;
  }
  .ring-4 {
    animation: rradar 0.2s ease-in-out 0.6s 1, rradar 0.2s ease-in-out 1.4s 1;
  }
}

@keyframes rradar {
  100% {
    opacity: 0;
  }
}

