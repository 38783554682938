.btn {
  display: inline-block;
  height: 4.5rem;
  padding: 0 var(--padding-sm);

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  text-decoration: none;
  line-height: 4.5rem;
  letter-spacing: var(--kerning-lg);
  text-align: center;

  border-radius: var(--btn-radius);

    @include mq(screen-md) {
      font-size: var(--font-size-xs);
    }

    &:hover {
      text-decoration: underline;
    }
    
}

input[type="submit"].btn,
button.btn {
  border: none;
  cursor: pointer;
}

.btn--red-fade {
  color: var(--color-brand-red);
  background-color: var(--color-brand-red-fade);

    &:hover {
      color: white;
      background-color: var(--color-brand-red);
    }

}

.btn--purple-fade {
  color: var(--color-brand-purple);
  background-color: var(--color-brand-purple-fade);

    &:hover {
      color: white;
      background-color: var(--color-brand-purple);
    }

}

.btn--white {
  color: var(--color-brand-purple);
  background-color: rgba(white, 0.9);

    &:hover {
      background-color: white;
    }

}

// Arrow icon buttons

.arrow-icon-btn {
  display: block;
  width: 2rem;
  height: 2rem;

    @include mq(screen-sm) {
      width: 4rem;
      height: 4rem;
    }

    svg {
      fill: currentColor;
    }
    
}

.arrow-icon-btn--red {
  color: var(--color-brand-red);

    &:hover {
      color: var(--color-grey-dark);
    }

}

// Text buttons

.text-btn {
  display: inline-block;
  font-family: var(--heading-font);
  font-size: var(--fs-fluid-xs);
  letter-spacing: var(--kerning-lg);
}

.text-btn--white {
  color: white;

    &:hover {
      opacity: 0.75;
    }

}

.text-btn--red {
  color: var(--color-brand-red);

    &:hover {
      opacity: 0.75;
    }

}

// Button groups

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-sm);
}