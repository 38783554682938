.location {
  display: grid;
  justify-items: start;
  gap: var(--padding-sm);
  margin: 0 0 var(--padding-md);
}

.address {

  li {
    line-height: var(--line-height-md);
  }

}