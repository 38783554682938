.contact-info {
  font-family: var(--heading-font);
  line-height: var(--line-height-lg);
  letter-spacing: var(--kerning-lg);
}

.emergency-contact-cta {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;

    svg {
      width: 2rem;
      height: 2rem;

      fill: white;
    }

}

.emergency-contact-cta--red {
  background-color: var(--color-brand-red);
}

.emergency-contact-cta--grey-dark {
  background-color: var(--color-grey-dark);
}
