@font-face {
  font-family: 'UniversLTStd';
  src: url('fonts/UniversLTStd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UniversLTStd-Light';
  src: url('fonts/UniversLTStd-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

