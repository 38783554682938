.tel-cta-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: var(--padding-sm) var(--padding-md);
  margin: 0 auto;

  @include mq(screen-lg) {
    width: var(--mq-lg);
    padding: var(--padding-sm) var(--padding-md) 0;
  }

}

.tel-cta {
  position: relative;

    p {
      font-weight: var(--font-weight-bold);
      font-size: var(--fs-fluid-xs);
      color: var(--color-brand-red);
    }
    
    span {
      color: var(--color-grey-dark);
    }

    svg {
      position: absolute;
      top: 50%;
      right: 95%;
      transform: translateY(-50%);
      z-index: 1;

      width: 4rem;
      height: 4rem;

      fill: var(--color-brand-purple-fade);
    }

}

.pg-header-outer > .tel-cta {
  display: none;

    @include mq(screen-lg) {
      display: block;
    }

}

.pg-header__btns .tel-cta {
  
  @include mq(screen-lg) {
    display: none;
  }

}

.pg-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-sm);

  max-width: var(--mq-lg);
  height: 8rem;
  padding: 0 var(--padding-md);
  margin: 0 auto;

    @include mq(screen-lg) {
      gap: var(--padding-md);
      height: 15rem;
    }
    
}

.pg-header__logo {
  display: block;
  width: 6rem;
  height: 6rem;

    @include mq(screen-lg) {
      width: 8rem;
      height: 8rem;
    }

}

// Main navigation

.main-nav {
  display: none;
  position: fixed;
  top: 13.5rem;
  left: 0;
  z-index: 10000;

  width: 100%;
  height: 100%;
  padding: var(--padding-lg) var(--padding-md);
  overflow-y: scroll;

  background-color: var(--color-grey-light);
  box-shadow: inset var(--feed-item-shadow);

    @include mq(screen-md) {
      position: static;

      display: flex;
      width: auto;
      height: auto;
      padding: 0;

      background: none;
      box-shadow: none;
      overflow-y: visible;
    }

}

.main-nav__list {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 0 16rem;

  display: grid;
  gap: 2rem;

    @include mq(screen-md) {
      display: flex;
      gap: var(--padding-md);
      padding: 0;
    }

    > li {
      position: relative;

      @include mq(screen-md) {
        padding: var(--padding-sm) 0;
      }

    }

    li.child {
      
      @include mq(screen-md) {
        display: none;
      }

    }

    a {
      text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

    }

    // Sub menus

    .sub-menu {
      display: none;
      gap: var(--padding-md); 
      margin: var(--padding-md) 0 0;
      padding: 0 0 0 var(--padding-xs);

      border-left: 3px solid var(--color-grey);

        @include mq(screen-md) {
          display: grid;
          //gap: var(--padding-xs);
          gap: 0;
          margin: 0;    
          padding: 1rem 0;    
          border: none;
        }

    }

    .sub-menu.is-open {
      display: grid;
    }

    .sub-menu li {
      
      @include mq(screen-md) {
        padding: 1rem var(--padding-sm);
      }

    }

    > li > .sub-menu {
      
      @include mq(screen-md) {
        display: grid;
        //transition: all 0.5s;
        opacity: 0;
        visibility: hidden;
        transform: translateY(0);

        width: 30rem;

        border: none;
        border-radius: var(--btn-radius);
        background-color: var(--color-brand-red-mid);
      }

    }

    > li:hover a {

      @include mq(screen-md) {
        color: var(--color-brand-red);
      }

    }

    > li:hover > .sub-menu {
      
      @include mq(screen-md) {
        opacity: 1;
        visibility: visible;
        transform: translateY(1rem);
        z-index: 1;
      }

    }

}

// Third level menus

@include mq(screen-md) {

  .sub-menu {
    position: absolute;
  }

  .sub-menu > .parent > .sub-menu {
    display: grid;
    opacity: 0;
    visibility: hidden;

    position: absolute;
    top: 0;
    left: calc(100% - 2rem);
    z-index: 2;

    width: 30rem;
    border-radius: var(--btn-radius);

    background-color: #91152e;
  }

  .sub-menu > .parent:hover > a {
    text-decoration: underline;
  }

  .sub-menu > .parent:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
  }

  .sub-menu > .child {
    display: none;
  }

}

.main-nav__list li.parent > a {
  position: relative;
  display: block;

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-sm);
  color: var(--color-grey-dark);
  text-decoration: none;
  letter-spacing: var(--kerning-lg);

    @include mq(screen-md) {
      font-size: var(--fs-fluid-xs);
    }
}

.main-nav__list li.parent li.parent a {
  
  @include mq(screen-md) {
    color: white;
  }

}

.main-nav__list li:not(.parent) > a {
  font-size: var(--fs-fluid-sm);
  color: var(--color-brand-red-mid);

    @include mq(screen-md) {
      font-size: var(--fs-fluid-xs);
      color: white;
    }

}

.main-nav__list li.parent > a:after {
  content: "";

  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 1;

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--color-grey-dark) transparent transparent transparent;

    @include mq(screen-md) {
      display: none;
    }

}

.mob-btns {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: grid;
  place-items: center;

  width: 100%;
  height: 8rem;
  padding: 0 var(--padding-md);

  background-color: white;
  border-top: 1px solid white;
  box-shadow: var(--feed-item-shadow-reverse);

    @include mq(screen-sm) {
      display: none;
    }

}

// Buttons

.pg-header__btns {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex: auto;
}

.search-toggle,
.main-nav-toggle {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  padding: 1.25rem;

  background: var(--color-brand-red-fade);
  border-radius: var(--btn-radius);

    &:hover {
      background: var(--color-brand-red);

        svg {
          fill: white;
        }

    }

    svg {
      fill: var(--color-brand-red);
    }

}

.main-nav-toggle {

  @include mq(screen-md) {
    display: none;
  }
  
  svg:last-child {
    display: none;
  }

  &.is-active {

    background-color: var(--color-brand-red-fade);
      
      svg:first-child {
        display: none;
      }

      svg:last-child {
        display: block;
      }

  }

}

// Mobile nav state

.pg-header-outer {

  &.nav-is-open {

    .main-nav {
      display: block;
    }

  }

}