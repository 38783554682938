.login-quicklinks {
  display: grid;
  gap: var(--padding-md);

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

}

.login-quicklink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--padding-xs);
  padding: var(--padding-sm) var(--padding-sm) var(--padding-md);

  background-color: var(--color-grey-light);
  border-bottom: 3px solid var(--color-grey);
}