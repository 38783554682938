.img-quicklinks {
  display: grid;
  grid-gap: var(--padding-xs);

    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-lg) {
      grid-template-columns: repeat(4, 1fr);
    }

}

.img-quicklink {
  display: grid;
  gap: var(--padding-xs);
  align-content: start;
  position: relative;
  padding: var(--padding-md);

  background-color: white;

    @include mq(screen-sm) {
      padding: var(--padding-sm) var(--padding-sm) 8rem;
    }

    .arrow-icon-btn {

      @include mq(screen-sm) {
        position: absolute;
        bottom: var(--padding-sm);
        left: var(--padding-sm);
      }

    }

}

.img-quicklink .title {

    @include mq(screen-lg) {
      min-height: 7.5rem;
    }

}

.img-quicklink__img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;

  object-fit: cover;
}