.quicklinks {
  display: grid;
  grid-gap: var(--padding-sm);
}

.quicklinks-text {
  display: grid;
  gap: var(--padding-sm);
  width: max(60%, 30rem);
}

.quicklink {
  display: grid;
  grid-gap: var(--padding-sm);
  padding: var(--padding-sm) var(--padding-sm) var(--padding-md);

  background-color: var(--color-grey-light);
  border-bottom: 3px solid var(--color-grey);

  font-family: var(--heading-font);
  font-size: var(--fs-fluid-md);
  text-decoration: none;
  color: var(--color-grey-dark);
  letter-spacing: var(--kerning-lg);

    &:hover {
      text-decoration: underline;
      border-color: var(--color-brand-grey-dark);
    }
    
}

.quicklink__icon {
  display: block;
  width: 2rem;
  height: 2rem;

    @include mq(screen-sm) {
      width: 4rem;
      height: 4rem;
    }
    
}

// Variants 

.inner--xl > .quicklinks {
  
  @include mq(screen-xs) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(screen-lg) {
    grid-template-columns: repeat(4, 1fr);
  }

}